import { createState, State, useState } from "@hookstate/core"
import { API, graphqlOperation } from "aws-amplify"
import { useRef } from "react"
import { useHistory } from "react-router-dom"
import Observable from "zen-observable-ts"
import { ConversationType, NotificationType, OnNotificationCreatedByLambdaSubscription } from "../../API"
import { RestrictedAreaTypes } from "../../backendServices/RestrictedAreaServices"
import { loadEventDateDetails } from "../../backendServices/EventdateServices"
import { fetchConversationNameAndType, fetchUserName } from "../../backendServices/GraphQLServices"
import branding from "../../branding/branding"
import { ChatConversationParam } from "../../communicationArea/ChatPage"
import { NetworkingListType } from "../../communicationArea/CommunicationArea"
import { ScheduleListType } from "../../communicationArea/ScheduleTab"
import { buildDetailLink } from "../../contentArea/detailPages/DetailNavLink"
import { useAppState } from "../../globalStates/AppState"
import { useLanguageState } from "../../globalStates/LanguageState"
import { useUserRestrictedAreaAccess } from "../../globalStates/UserRestrictedAreaAccess"
import { onNotificationCreatedByLambda } from "../../graphql/ownSubscriptions"
import { isExplorationOrPostEventPhase } from "../../utils/EventPhaseChecker"

export interface Notification {
    userId: string
    type: NotificationType
    title: string
    text: string
    userName: string
    organizationName?: string
    restrictedAreaId?: string
    restrictedAreaType?: string
    onClick: () => void
    onClose?: () => void
}

interface PlainTextNotificationContent {
    type: string
    text: string
    title: string
    texts_localized: any
}

interface NetworkingNotificationContent {
    userId: string
    userName: string
    pictureUrl: string
    message: string
    action: string
}

interface ChatMessageNotificationContent {
    msgId: string
    msgContent: string
    msgCreatedAt: Date
    convId: string
    authorId: string
}

interface CalendarEntryNotificationContent {
    type: string
    senderName: string
    start: string
    end: string
    title: string
    description: string
    locationName: string
    isVirtual: boolean
}

interface BackofficeNotificationContent {
    userId: string
    userName: string
    message: string
    action: string
    organizationName: string
    organizationId: string
    restrictedAreaId: string
    restrictedAreaType: string
    restrictedAreaName: string
    restrictedAreaNameDe: string
}

enum CalendarEntryNotificationType {
    MEETING_REQUEST_DELETED = "meetingrequestdeleted",
    MEETING_REQUEST = "meetingrequest",
    MEETING_REQUEST_PARTICIPATION_DELETED = "meetingrequestparticipationdeleted",
    MEETING_REQUEST_PARTICIPATION_ACCEPTED = "meetingrequestparticipationaccepted",
    MEETING_REQUEST_PARTICIPATION_DECLINED = "meetingrequestparticipationdeclined",
    MEETING_REQUEST_UPDATED = "meetingrequestupdated",
    MEETING_REQUEST_ORGANIZATION_ACCEPTED = "meetingrequestorganizationaccepted",
    MEETING_REQUEST_ORGANIZATION_DECLINED = "meetingrequestorganizationeclined"
}

interface StateValues {
    notifications: Notification[]
    mutedChatConversationIds: string[]
    userProfileId: string
}

interface NotificationCreatedSubscription {
    value: {
        data: OnNotificationCreatedByLambdaSubscription
    }
}

const getStartValues = (): StateValues => {
    return {
        notifications: [],
        mutedChatConversationIds: [],
        userProfileId: ""
    }
}

const audio = new Audio("/branding/sounds/notificationSound.mp3")
let onNotificationCreatedHandle: any
let onSystemNotificationCreatedHandle: any
const useWrapState = (stateValues: State<StateValues>) => {
    const audioRef = useRef(audio) // Audio Ref is strange

    const appState = useAppState()
    const history = useHistory()
    const languageState = useLanguageState()
    const language = languageState.getLanguage()
    const userAccessState = useUserRestrictedAreaAccess()

    const addNotification = (notification: Notification) => {
        state.set((prevState) => {
            prevState.notifications = prevState.notifications.concat([notification])
            return prevState
        })
    }

    const isMutedChat = (msgNotification: ChatMessageNotificationContent) => {
        return state.mutedChatConversationIds.value.includes(msgNotification.convId)
    }

    const handlePlainTextNotification = (content: PlainTextNotificationContent) => {
        const appLanguage = languageState.getLanguage()
        let text = content.text
        const localizedTexts = content.texts_localized
        if (localizedTexts) {
            if (appLanguage === "en" && localizedTexts.en) {
                text = localizedTexts.en
            } else if (appLanguage === "de" && localizedTexts.de) {
                text = localizedTexts.de
            }
        }
        return [content.title, text]
    }

    const handleNetworkingNotification = (content: NetworkingNotificationContent) => {
        var title = ""
        var userName = ""
        var text = ""

        switch (content.action) {
            case "REQUEST": {
                title = branding.notification.connectRequestTitle
                userName = content.userName
                text = replaceNetworkingNotificationPlaceholderText(
                    content,
                    branding.notification.connectRequestIncomingTextTemplate
                )
                break
            }
            case "CONNECT": {
                title = branding.notification.connectRequestTitle
                userName = content.userName
                text = replaceNetworkingNotificationPlaceholderText(
                    content,
                    branding.notification.connectRequestAcceptedTextTemplate
                )
                break
            }
            default:
                return []
        }

        return [title, userName, text]
    }

    const handleChatMessageNotification = (
        msgNotification: ChatMessageNotificationContent,
        convType: ConversationType,
        authorName: string,
        convName?: string
    ) => {
        if (msgNotification.authorId === state.userProfileId.value || isMutedChat(msgNotification)) {
            return []
        }
        if (convType === ConversationType.GROUP) {
            let content = authorName + ": " + msgNotification.msgContent
            if (msgNotification.msgContent.startsWith("systemAdded")) {
                content = content.split("/")[1] + " " + branding.chatBranding.participantJoinedMessageText
            } else if (msgNotification.msgContent.startsWith("systemDeleted")) {
                content = content.split("/")[1] + " " + branding.chatBranding.participantRemovedMessageText
            } else if (msgNotification.msgContent.startsWith("systemExited")) {
                content = content.split("/")[1] + " " + branding.chatBranding.participantRemovedMessageText
            }

            return [convName ?? branding.chatBranding.groupChat, content]
        }

        return [branding.notification.newMessageTitle, authorName + ": " + msgNotification.msgContent]
    }

    const onChatMessageNotificationClick = (msgNotification: ChatMessageNotificationContent, convType: ConversationType) => {
        const param = ChatConversationParam.conversationByConversationId(convType, msgNotification.convId)
        appState.setShowChatsTab(param)
    }

    const onNetworkingNotificationClick = (notificationAction: string) => {
        switch (notificationAction) {
            case "REQUEST": {
                appState.setShowPeopleTab(NetworkingListType.REQUESTS)
                break
            }
            case "CONNECT": {
                appState.setShowPeopleTab(NetworkingListType.CONTACTS)
                break
            }
            default:
                return []
        }
    }

    const replaceCalendarEntryNotificationPlaceholderText = (content: CalendarEntryNotificationContent, template: string) => {
        var contentString = template.split("{$meetingTitle}").join(content.title)
        contentString = contentString.split("{$senderName}").join(content.senderName)
        contentString = contentString
            .split("{$meetingLocationTemplate}")
            .join(getMeetingLocationText(content.locationName, content.isVirtual, language))

        return [contentString, content.senderName]
    }

    const getMeetingLocationText = (locationName: string, isVirtual: boolean, language: string) => {
        let text: string = "test"

        if (locationName.length > 0 && !isVirtual) {
            text = branding.notification.meetingLocationNotVirtualTextTemplate
        } else if (locationName.length > 0 && isVirtual) {
            text = branding.notification.meetingNoLocationVirtualTextTemplate
        } else if (locationName === "" && isVirtual) {
            text = branding.notification.meetingNoLocationVirtualTextTemplate
        }

        text = text.split("{$meetingLocation}").join(locationName)

        return text
    }

    const replaceNetworkingNotificationPlaceholderText = (content: NetworkingNotificationContent, template: string) => {
        var contentString = template.split("{$senderName}").join(content.userName)

        return contentString
    }

    const replaceBackofficeNotificationPlaceholderText = async (content: BackofficeNotificationContent, template: string) => {
        var contentString = template.split("{$userName}").join(content.userName)

        if (content.restrictedAreaType === RestrictedAreaTypes.VirtualCafe.value) {
            const virtualCafe = branding.meetingRoomGroups.find(
                (meetingRoomGroup) => meetingRoomGroup.id === content.restrictedAreaId
            )
            if (virtualCafe) {
                contentString = contentString.split("{$restrictedAreaName}").join(virtualCafe.title)
            } else {
                return undefined
            }
        } else if (content.restrictedAreaType === RestrictedAreaTypes.PrivateEvent.value) {
            try {
                const eventDateResp = await loadEventDateDetails({ id: content.restrictedAreaId })
                const eventDateName = eventDateResp.content.name
                if (eventDateName) {
                    contentString = contentString.split("{$restrictedAreaName}").join(eventDateName)
                } else {
                    return undefined
                }
            } catch (error) {
                return undefined
            }
        } else if (content.restrictedAreaType === RestrictedAreaTypes.ConferenceRoom.value) {
            const conferenceRoomTitle =
                languageState.getLanguage() === "en" ? content.restrictedAreaName : content.restrictedAreaNameDe
            if (conferenceRoomTitle) {
                contentString = contentString.split("{$restrictedAreaName}").join(conferenceRoomTitle)
            } else {
                return undefined
            }
        }

        return contentString
    }

    const updateUserAccess = (content: BackofficeNotificationContent): void => {
        switch (content.restrictedAreaType) {
            case RestrictedAreaTypes.VirtualCafe.value: {
                userAccessState.fetchUserAccessForAllVirtualCafes()
                break
            }
            case RestrictedAreaTypes.RoundTable.value: {
                userAccessState.fetchUserAccessForAllEventDates()
                break
            }
            case RestrictedAreaTypes.PrivateEvent.value: {
                userAccessState.fetchUserAccessForAllEventDates()
                break
            }
            case RestrictedAreaTypes.ConferenceRoom.value: {
                userAccessState.fetchUserAccessForAllConferenceRooms()
                break
            }
            default: {
                userAccessState.fetchUserAccessForAllRestrictedAreaTypes()
                break
            }
        }
    }

    const handleBackofficeNotification = async (content: BackofficeNotificationContent) => {
        var title = ""
        var userName = ""
        var text = undefined
        var organizationName = ""
        var restrictedAreaId = content.restrictedAreaId
        var restrictedAreaType = content.restrictedAreaType

        switch (content.action) {
            case "request": {
                title = branding.notification.accessRequestTitle
                userName = content.userName
                text = await replaceBackofficeNotificationPlaceholderText(content, branding.notification.accessRequestText)
                break
            }
            case "accept": {
                title = branding.notification.accessAcceptedTitle
                organizationName = content.organizationName
                userName = content.userName
                updateUserAccess(content)
                text = await replaceBackofficeNotificationPlaceholderText(content, branding.notification.accessAcceptedText)
                break
            }
            case "decline": {
                title = branding.notification.accessDeclinedTitle
                organizationName = content.organizationName
                userName = content.userName
                updateUserAccess(content)
                text = await replaceBackofficeNotificationPlaceholderText(content, branding.notification.accessDeclinedText)
                break
            }
            case "delete": {
                title = branding.notification.accessDeletedTitle
                organizationName = content.organizationName
                userName = content.userName
                window.onbeforeunload = null
                window.location.reload()
                updateUserAccess(content)
                text = await replaceBackofficeNotificationPlaceholderText(content, branding.notification.accessDeletedText)
                break
            }
            case "added": {
                title = branding.notification.accessAddedTitle
                userName = content.userName
                updateUserAccess(content)
                text = await replaceBackofficeNotificationPlaceholderText(content, branding.notification.accessAddedText)
                break
            }
            default:
                return []
        }
        return [title, userName, text, organizationName, restrictedAreaId, restrictedAreaType]
    }

    const onBackofficeNotificationClick = (content: BackofficeNotificationContent) => {
        if (content.action === "request") {
            if (content.restrictedAreaId && content.restrictedAreaType) {
                history.push(
                    `${buildDetailLink(
                        content.organizationId,
                        content.organizationName,
                        "organization"
                    )}/backoffice?restrictedAreaId=${content.restrictedAreaId}&restrictedAreaType=${content.restrictedAreaType}`
                )
            }
        } else if (content.action === "added" || content.action === "accept") {
            if (!isExplorationOrPostEventPhase) {
                if (content.restrictedAreaType === RestrictedAreaTypes.VirtualCafe.value) {
                    history.push(`${buildDetailLink(content.restrictedAreaId, "", "virtualCafeMeetingGroup")}`)
                } else if (
                    content.restrictedAreaType === RestrictedAreaTypes.RoundTable.value ||
                    content.restrictedAreaType === RestrictedAreaTypes.PrivateEvent.value
                ) {
                    history.push(`${buildDetailLink(content.restrictedAreaId, "", "eventdate")}`)
                } else if (content.restrictedAreaType === RestrictedAreaTypes.ConferenceRoom.value) {
                    history.push(`${buildDetailLink(content.restrictedAreaId, "", "conferenceRoom")}`)
                }
            } else {
                history.push("/meetings")
            }
        }
    }

    const handleCalendarEntryNotification = (content: CalendarEntryNotificationContent) => {
        var title = ""
        var text = ""
        var userName = ""
        switch (content.type) {
            case CalendarEntryNotificationType.MEETING_REQUEST:
                title = branding.notification.meetingRequestTitle
                text = replaceCalendarEntryNotificationPlaceholderText(
                    content,
                    branding.notification.meetingRequestTextTemplate
                )[0]
                userName = replaceCalendarEntryNotificationPlaceholderText(
                    content,
                    branding.notification.meetingRequestTextTemplate
                )[1]
                break
            case CalendarEntryNotificationType.MEETING_REQUEST_DELETED:
                title = branding.notification.meetingRequestTitle
                text = replaceCalendarEntryNotificationPlaceholderText(
                    content,
                    branding.notification.meetingRequestDeletedTextTemplate
                )[0]
                userName = replaceCalendarEntryNotificationPlaceholderText(
                    content,
                    branding.notification.meetingRequestDeletedTextTemplate
                )[1]
                break
            case CalendarEntryNotificationType.MEETING_REQUEST_PARTICIPATION_DELETED:
                title = branding.notification.meetingRequestTitle
                text = replaceCalendarEntryNotificationPlaceholderText(
                    content,
                    branding.notification.meetingRequestParticipationDeletedTextTemplate
                )[0]
                userName = replaceCalendarEntryNotificationPlaceholderText(
                    content,
                    branding.notification.meetingRequestParticipationDeclinedTextTemplate
                )[1]
                break
            case CalendarEntryNotificationType.MEETING_REQUEST_ORGANIZATION_ACCEPTED:
            case CalendarEntryNotificationType.MEETING_REQUEST_PARTICIPATION_ACCEPTED:
                title = branding.notification.meetingRequestTitle
                text = replaceCalendarEntryNotificationPlaceholderText(
                    content,
                    branding.notification.meetingRequestParticipationAcceptedTextTemplate
                )[0]
                userName = replaceCalendarEntryNotificationPlaceholderText(
                    content,
                    branding.notification.meetingRequestParticipationAcceptedTextTemplate
                )[1]
                break
            case CalendarEntryNotificationType.MEETING_REQUEST_ORGANIZATION_DECLINED:
            case CalendarEntryNotificationType.MEETING_REQUEST_PARTICIPATION_DECLINED:
                title = branding.notification.meetingRequestTitle
                text = replaceCalendarEntryNotificationPlaceholderText(
                    content,
                    branding.notification.meetingRequestParticipationDeclinedTextTemplate
                )[0]
                userName = replaceCalendarEntryNotificationPlaceholderText(
                    content,
                    branding.notification.meetingRequestParticipationDeclinedTextTemplate
                )[1]
                break
            case CalendarEntryNotificationType.MEETING_REQUEST_UPDATED:
                title = branding.notification.meetingRequestTitle
                text = replaceCalendarEntryNotificationPlaceholderText(
                    content,
                    branding.notification.meetingRequestUpdatedTextTemplate
                )[0]
                userName = replaceCalendarEntryNotificationPlaceholderText(
                    content,
                    branding.notification.meetingRequestUpdatedTextTemplate
                )[1]
                break
            default:
                return []
        }

        return [title, text, userName]
    }

    const onCalendarEntryNotificationClick = (contentType: CalendarEntryNotificationType) => {
        switch (contentType) {
            case CalendarEntryNotificationType.MEETING_REQUEST:
                appState.setShowScheduleTab(ScheduleListType.PENDING)
                break
            default:
                appState.setShowScheduleTab(ScheduleListType.CONFIRMED)
                break
        }
    }

    return {
        init: (userId: string) => {
            state.set((prevState) => {
                prevState.userProfileId = userId
                return prevState
            })
            if (onNotificationCreatedHandle === undefined) {
                onNotificationCreatedHandle = (
                    API.graphql(
                        graphqlOperation(onNotificationCreatedByLambda, { userId: userId })
                    ) as Observable<NotificationCreatedSubscription>
                ).subscribe({
                    next: handleNotificationReceived
                })
            }
            // HINT: Resolvers are currently using the UserPool as the sotName since SOT is not available for the CognitoUsers.
            // TODO: Remove this hint when the TODOs in the relevant Resolvers are solved.
            // Relevant resolvers:
            //  - Subscription.onNotificationCreated.req
            //  - Query.notificationsByUserIdAndDisplayGroupSorted.res
            if (onSystemNotificationCreatedHandle === undefined) {
                onSystemNotificationCreatedHandle = (
                    API.graphql(
                        graphqlOperation(onNotificationCreatedByLambda, { userId: branding.configuration.sotName })
                    ) as Observable<NotificationCreatedSubscription>
                ).subscribe({
                    next: handleNotificationReceived
                })
            }
            async function handleNotificationReceived(resp: NotificationCreatedSubscription) {
                const notification = resp.value.data.onNotificationCreated
                if (!notification) {
                    return
                }
                const content = JSON.parse(notification.content)
                switch (notification.type) {
                    case NotificationType.PLAINTEXT: {
                        const [title, text] = handlePlainTextNotification(content)
                        addNotification({
                            userId,
                            title,
                            text,
                            type: notification.type,
                            userName: "",
                            onClick: () => {}
                        })
                        break
                    }
                    case NotificationType.NETWORKING: {
                        const [title, userName, text] = handleNetworkingNotification(content)
                        addNotification({
                            userId,
                            title,
                            text,
                            type: notification.type,
                            userName: userName,
                            onClick: () => onNetworkingNotificationClick(content.action)
                        })
                        break
                    }
                    case NotificationType.MESSAGE: {
                        const { convType, convName } = await fetchConversationNameAndType(content.convId)
                        const { userName, userPictureUrl } = await fetchUserName(content.authorId) // eslint-disable-line @typescript-eslint/no-unused-vars
                        const messageContent = { ...content, msgCreatedAt: new Date(content.msgCreatedAt) }
                        if (!userName) break
                        const [title, text] = handleChatMessageNotification(messageContent, convType, userName, convName)
                        if (title && text) {
                            // TODO show picture in notification
                            audioRef.current.play()

                            addNotification({
                                userId,
                                title,
                                text,
                                type: notification.type,
                                userName: userName,
                                onClick: () => onChatMessageNotificationClick(messageContent, convType)
                            })
                        }
                        break
                    }
                    case NotificationType.CALENDARENTRY: {
                        const [notificationTitle, notificationText, userName] = handleCalendarEntryNotification(content)
                        if (notificationTitle && notificationTitle) {
                            addNotification({
                                userId: userId,
                                type: notification.type,
                                title: notificationTitle,
                                text: notificationText,
                                userName: userName,
                                onClick: () => onCalendarEntryNotificationClick(content.type)
                            })
                        }
                        break
                    }
                    case NotificationType.BACKOFFICE: {
                        const [title, userName, text, organizationName, restrictedAreaId, restrictedAreaType] =
                            await handleBackofficeNotification(content)
                        if (text && title && userName) {
                            addNotification({
                                userId: userId,
                                title: title,
                                text: text,
                                type: notification.type,
                                userName: userName,
                                organizationName: organizationName,
                                restrictedAreaId: restrictedAreaId,
                                restrictedAreaType: restrictedAreaType,
                                onClick: () => onBackofficeNotificationClick(content)
                            })
                        }
                        break
                    }
                    default:
                        return
                }
            }
        },
        getNotifications: () => {
            return stateValues.value.notifications
        },
        removeNotification: (notification: Notification) => {
            state.set((prevState) => {
                const indexToRemove = prevState.notifications.indexOf(notification)
                if (indexToRemove > -1) {
                    prevState.notifications = [
                        ...prevState.notifications.slice(0, indexToRemove),
                        ...prevState.notifications.slice(indexToRemove + 1)
                    ]
                }
                return prevState
            })
        },
        addMutedConversationId: (conversationId: string) => {
            state.set((prevState) => {
                if (!prevState.mutedChatConversationIds.includes(conversationId)) {
                    prevState.mutedChatConversationIds = prevState.mutedChatConversationIds.concat([conversationId])
                }
                return prevState
            })
        },
        removeMutedConversationId: (conversationId: string) => {
            state.set((prevState) => {
                const indexToRemove = prevState.mutedChatConversationIds.indexOf(conversationId)
                if (indexToRemove > -1) {
                    prevState.mutedChatConversationIds = [
                        ...prevState.mutedChatConversationIds.slice(0, indexToRemove),
                        ...prevState.mutedChatConversationIds.slice(indexToRemove + 1)
                    ]
                }
                return prevState
            })
        },
        unsubscribe: () => {
            onNotificationCreatedHandle?.unsubscribe()
            onSystemNotificationCreatedHandle?.unsubscribe()

            onNotificationCreatedHandle = undefined
            onSystemNotificationCreatedHandle = undefined
        }
    }
}
const state = createState<StateValues>(getStartValues())
export const useNotificationContext = () => useWrapState(useState(state))
