import { useEffect, useRef, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import Select, { ActionMeta, OptionTypeBase, Theme, ValueType } from "react-select"
import styled from "styled-components"
import branding from "../../../branding/branding"
import { useAppState } from "../../../globalStates/AppState"
import CrsTabs, { TabItemType } from "../../../ui/CrsTabs"
import { IconCamera, IconClose } from "../../../ui/Icons"
import { useVideoContext } from "../../context/VideoContext"
import { useDevices } from "../../hooks/useDevices"
import { AudioSettings } from "./audio/AudioSettings"
import { BackgroundSettings } from "./background/BackgroundSettings"
import { LogoSettings } from "./logo/LogoSettings"
import { VideoSettings } from "./video/VideoSettings"
import "@mediapipe/selfie_segmentation/selfie_segmentation_solution_simd_wasm_bin.js"

const SettingsBackground = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
    z-index: 9999999;
    animation: fadein 0.1s;

    @keyframes fadein {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
`

const SettingsRoot = styled.div`
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #fff;
    border-bottom: none;
    width: 576px;
    height: 357px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`

const CloseBtn = styled.div`
    position: absolute;
    right: 10px;
    top: 5px;
    z-index: 15;
    width: 15px;
    height: 15px;
    cursor: pointer;
`

interface SettingsProps {
    open: boolean
}

type TabKey = "audio" | "video" | "background" | "logo"

export default function Settings(props: SettingsProps) {
    const appState = useAppState()
    const [activeTab, setActiveTab] = useState<TabKey>("audio")
    const settingsRootRef = useRef<any>()

    useEffect(() => {
        /**
         * The active tab should always be audio tab when opening
         * the modal and not the last visited tab which is
         * the default behaviour of the component
         */
        setActiveTab("audio")
    }, [appState.isAudioVideoSettingsOpen])

    useEffect(() => {
        // close the modal with the esc button
        const close = (e: any) => {
            if (e.keyCode === 27) {
                appState.setIsAudioVideoSettingsOpen(false)
            }
        }
        window.addEventListener("keydown", close)
        return () => window.removeEventListener("keydown", close)
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        // close the modal with outside click
        const close = (e: any) => {
            if (!settingsRootRef.current?.contains(e.target)) {
                appState.setIsAudioVideoSettingsOpen(false)
            }
        }
        window.addEventListener("mousedown", close)
        return () => window.removeEventListener("mousedown", close)

        // eslint-disable-next-line
    }, [])

    if (!props.open) return null

    const tabItemTypes: TabItemType[] = [
        {
            label: branding.audioVideoSettings.tabTitleAudio,
            value: "audio"
        },
        {
            label: branding.audioVideoSettings.tabTitleVideo,
            value: "video"
        },
        {
            label: branding.audioVideoSettings.tabTitleLogo,
            value: "logo"
        },
        {
            label: branding.audioVideoSettings.tabTitleBackground,
            value: "background"
        }
    ]

    return (
        <SettingsBackground>
            <SettingsRoot ref={settingsRootRef}>
                <CloseBtn
                    onClick={() => {
                        appState.setIsAudioVideoSettingsOpen(false)
                    }}
                >
                    {IconClose({ fill: "#727272", height: "10", width: "10" })}
                </CloseBtn>
                <CrsTabs
                    centerItems={true}
                    hideControls={true}
                    width={"75%"}
                    tabItemFontSize={"14px"}
                    tabItemPadding={100}
                    tabItems={tabItemTypes}
                    activeItem={activeTab}
                    onTabItemClicked={(tabKey) => setActiveTab(tabKey as TabKey)}
                    style={{ backgroundColor: "transparent", padding: "0", height: "54px" }}
                />
                <TabContentSwitcher selectedTab={activeTab} />
            </SettingsRoot>
        </SettingsBackground>
    )
}

export const SelectThemeCustom: any = (theme: Theme) => ({
    ...theme,
    colors: {
        ...theme?.colors,
        primary: branding.dropdownStyle.primaryColor,
        primary25: branding.dropdownStyle.primary25Color,
        primary50: branding.dropdownStyle.primary50Color,
        primary75: branding.dropdownStyle.primary75Color
    }
})

export const StyledSelect = styled(Select)<{ width?: string }>`
    width: ${(props) => (props.width ? props.width : "250px")};
    margin-right: 10px;
    font-size: 12px;
    font-family: ${branding.font1};
    color: ${branding.mainInfoColor};
`

export const SettingsContentContainer = styled(Container)`
    height: 100%;
    width: 100%;
    padding: 20px;
`

const CenteredMessage = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100% - 50px);
    color: ${branding.sideIconBar.sideIconColorDark};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    font-family: ${branding.font1};
    text-align: center;
`

interface TabContentSwitcherProps {
    selectedTab: TabKey
}
function TabContentSwitcher(props: TabContentSwitcherProps) {
    const devices = useDevices()
    // eslint-disable-next-line
    const [deviceSwitcherState, setDeviceSwitcherState] = useState<any>(devices)

    useEffect(() => {
        /**
         * Sometimes the user enable the browser permissions later.
         * Make sure we refresh the state of the available devices.
         */
        devices.ensureDevices()
    }, [devices])

    switch (props.selectedTab) {
        case "audio":
            return hasMicrophonePermission(deviceSwitcherState) ? (
                <AudioSettings />
            ) : (
                <NotAwailableSetting selectedTab={props.selectedTab} />
            )
        case "video":
            return isInConferenceRoom() && hasCameraPermission(deviceSwitcherState) ? (
                <VideoSettings />
            ) : (
                <NotAwailableSetting selectedTab={props.selectedTab} />
            )
        case "background":
            return isInConferenceRoom() && hasCameraPermission(deviceSwitcherState) ? (
                <BackgroundSettings />
            ) : (
                <NotAwailableSetting selectedTab={props.selectedTab} />
            )
        case "logo":
            return isInConferenceRoom() && hasCameraPermission(deviceSwitcherState) ? (
                <LogoSettings />
            ) : (
                <NotAwailableSetting selectedTab={props.selectedTab} />
            )
        default:
            return null
    }
}

function NotAwailableSetting(key: any) {
    let message = ""
    const deviceSwitcherState = useDevices()
    const videoContext = useVideoContext()
    // messages related to video input
    if (hasCameraPermission(deviceSwitcherState)) {
        switch (key.selectedTab) {
            case "video":
                message = branding.communicationAreaAudioVideoSettingsNotAvailablePreviewMessage
                break
            case "background":
                message = branding.communicationAreaAudioVideoSettingsNotAvailableBackgroundMessage

                break
            case "logo":
                message = branding.communicationAreaAudioVideoSettingsNotAvailableLogoMessage
                break
            default:
                message = ""
                break
        }
    } else {
        message = branding.communicationAreaAudioVideoSettingsVideoPermissionMessage
    }

    // messages related to audio input
    if (!hasMicrophonePermission(deviceSwitcherState) && key.selectedTab === "audio") {
        message = branding.communicationAreaAudioVideoSettingsAudioPermissionMessage
    }

    return (
        <SettingsContentContainer>
            <CenteredMessage>{message}</CenteredMessage>
            {key.selectedTab === "video" && (
                <Row>
                    <Col md={1} className={"pt-1"}>
                        {IconCamera({ fill: branding.sideIconBar.sideIconColorDark })}
                    </Col>
                    <Col className={"pl-0"} md={11}>
                        <StyledSelect
                            placeholder={branding.audioVideoSettings.videoInputLabel}
                            isMulti={false}
                            isSearchable={false}
                            isClearable={false}
                            options={deviceSwitcherState.videoInputDevices()}
                            value={deviceSwitcherState.currentVideoInputDevice()}
                            onChange={(value: ValueType<OptionTypeBase, boolean>, action: ActionMeta<OptionTypeBase>) => {
                                if (value !== null && action.action === "select-option") {
                                    const option = value as OptionTypeBase
                                    deviceSwitcherState.setVideoInputDevice(option.deviceId, option.label)
                                    videoContext.updateVideoInputDevice()
                                }
                            }}
                            theme={SelectThemeCustom}
                        />
                    </Col>
                </Row>
            )}
        </SettingsContentContainer>
    )
}

function isInConferenceRoom() {
    return window.location.href.includes("/meeting/")
}

function hasCameraPermission(deviceSwitcherState: any) {
    return deviceSwitcherState.videoInputDevices().length > 0 || null
}

function hasMicrophonePermission(deviceSwitcherState: any) {
    return deviceSwitcherState.audioInputDevices().length > 0 || null
}
