import { useEffect, useState, Suspense } from "react"
import * as React from "react"
import { TransitionGroup } from "react-transition-group"
import styled, { ThemeProvider } from "styled-components"
import "./App.css"
import { getCategoriesByTopic } from "./backendServices/TopicServices"
// https://blog.agney.dev/styled-components-&-typescript/
import { useLoggedInState } from "./globalStates/LoggedInUser"
import { useAppState } from "./globalStates/AppState"
import ContentAreaErrorBoundary from "./contentArea/errorPages/ContentAreaErrorBoundary"
import { useLanguageState } from "./globalStates/LanguageState"
import CenteredLoader from "./ui/CenteredLoader"
import { useNotificationContext } from "./conference/context/NotificationContext"
import { detect } from "detect-browser"

import Amplify, { Auth } from "aws-amplify"
import SideIconBar from "./navigationArea/SideIconBar/SideIconBar"
import NotificationCenter from "./ui/NotificationCenter"
import ConferenceOverlay from "./conference/ConferenceOverlay"
import awsmobile from "./aws-exports"
import { editMyProfilePageRoute } from "./navigationArea/RoutePaths"
import { useHistory } from "react-router-dom"
import branding from "./branding/branding"
import { useCategoriesState } from "./globalStates/CategoriesState"
import Alert from "./ui/CrsAlert"
import { useAlertState } from "./globalStates/AlertState"
import HailingOverlay from "./conference/HailingOverlay"
import { useChimeContext } from "./conference/context/ChimeContext"
import { accessPresenceState, EventType } from "./ui/PresenceIndicator"
import useWindowDimensions from "./ui/WindowDimensionsHook"
import PictureInPictureVideoPlayer from "./contentArea/videoPlayer/PictureInPictureVideoPlayer"
import { useDevices } from "./conference/hooks/useDevices"
import { BackendServiceError } from "./backendServices/BackendServicesUtils"
import { loadUserData, UserResponse } from "./backendServices/SeriesOfTopicsUserServices"
import { DataPrivacyDoc, TokenResponse, getDataPrivacyDocs, createAppDevice } from "./backendServices/AuthenticationServices"
import { updateUserValues } from "./backendServices/GraphQLServices"
import Settings from "./conference/components/settings/Settings"
import { lightTheme, MeetingProvider } from "amazon-chime-sdk-component-library-react"
import UserRegistrationSite from "./contentArea/userRegistration/UserRegistrationSite"
import { areRequiredFieldsEmpty } from "./contentArea/myprofile/EditMyProfileLayout"
import { syncFavorites } from "./contentArea/login/LoginHelper"
import { useFavoriteState } from "./globalStates/Favorites"
import { useMeetingInvitation } from "./conferenceV2/context/MeetingInvitation"
import HailingOverlayV2 from "./conferenceV2/HailingOverlay/HailingOverlayV2"

// Amplify.Logger.LOG_LEVEL = 'DEBUG'
Amplify.configure({
    ...awsmobile,
    Auth: {
        authenticationFlowType: "USER_PASSWORD_AUTH",
        mandatorySignIn: true
    },
    API: {
        graphql_headers: async () => {
            const session = await Auth.currentSession()
            return {
                // use the id token instead of the access token to be able to access the users' custom claims like 'custom:topic'
                Authorization: session.getIdToken().getJwtToken()
            }
        }
    }
})

const LoginSite = React.lazy(() => import("./contentArea/login/LoginSite"))
const CommunicationArea = React.lazy(() => import("./communicationArea/CommunicationArea"))
const ContentArea = React.lazy(() => import("./contentArea/ContentArea"))

interface AppSiteProps {}
const AppSiteRoot = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    display: flex;

    .shadow_method {
        background-color: rgb(18, 176, 41);
        box-shadow: inset 0 0 0 99999px rgba(255, 255, 255, 0.2);
    }
    ∏ .shadow_method:hover {
        box-shadow: none;
    }
`

const DarkenOverlay = styled.div`
    display: flex;
    position: absolute;
    opacity: 0.3;
    background-color: ${branding.darkenOverlayColor ?? "black"};
    width: 100%;
    height: 100%;
    z-index: 11;

    & > * {
        flex-shrink: 0;
    }
`
//const USER_REFRESH_INTERVAL = 5 * 60 * 1000

function isUserValid(user: any) {
    if (
        areRequiredFieldsEmpty(user!, branding.userRegistrationSite.basicInfoFieldsList, "en") ||
        areRequiredFieldsEmpty(user!, branding.userRegistrationSite.contactFieldsList, "en") ||
        areRequiredFieldsEmpty(user!, branding.userRegistrationSite.socialMediaFieldsList, "en") ||
        (branding.myProfilePageContent.requiredInterestsNumber > 0 &&
            (user?.interests ?? []).length < branding.myProfilePageContent.requiredInterestsNumber)
    ) {
        return false
    }

    return true
}

export function isUserEdited(userState: any): boolean {
    if (branding.userRegistrationSite.enabled && userState.user()) {
        return isUserValid(userState.user()!)
    }
    if (userState.user()?.firstName !== undefined && userState.user()?.lastName !== undefined) {
        return true
    } else {
        return false
    }
}

function startLoading() {
    if (!localStorage.getItem("loadingInProcess")) localStorage.setItem("loadingInProcess", "true")
}

function stopLoading() {
    if (localStorage.getItem("loadingInProcess")) {
        setTimeout(() => {
            localStorage.removeItem("loadingInProcess")
        }, 2000)
    }
}

const AppSite: React.FC<AppSiteProps> = (props) => {
    const [navbarOpen, setNavbarOpen] = useState(false)
    const [trigger, setTrigger] = useState(0)
    const languageState = useLanguageState()
    const lang = languageState.getLanguage()
    const [receptionRoute, setReceptionRoute] = useState(
        window.location.pathname === "/" ? "LOBBY" : branding.receptionPage.receptionPageMyHeaderTitle
    )
    const [reloadScheduleList, setReloadScheduleList] = useState<boolean>(false)
    const browserInfo = detect()
    const audioFileFormat = browserInfo?.name === "safari" ? "caf" : "opus"
    const notificationContext = useNotificationContext()
    const appState = useAppState()
    const userLink = useLoggedInState()
    const categoriesLink = useCategoriesState()
    const deviceSwitcherState = useDevices()
    const history = useHistory()
    const { isMobile } = useWindowDimensions()
    const profileId = userLink.user()?.profileId
    const chime = useChimeContext()
    const meetingInvitation = useMeetingInvitation()

    useEffect(() => {
        document.documentElement.lang = lang
    }, [lang])

    useEffect(() => {
        if (!profileId || localStorage.getItem("loadingInProcess")) return

        startLoading()
        //const refreshUserDataInterval = setInterval(() => {
        if (userLink.user() && profileId) {
            loadUserData({ loggedInUserId: profileId, targetProfileId: profileId }).then((res) => {
                if ((res as BackendServiceError).httpStatus) {
                    // TODO error handling
                } else {
                    userLink.setType((res as UserResponse).content.type)
                }
            })
        }
        //}, USER_REFRESH_INTERVAL)*/
        if (userLink.user() && profileId && !isUserEdited(userLink) && !branding.userRegistrationSite.enabled) {
            history.push(editMyProfilePageRoute)
        }

        //forcing new audio/video check on app loading
        deviceSwitcherState.ensureDevices()

        // Fetching categories info and storing them inside of the global state
        getCategories()

        stopLoading()

        /*return () => {
            clearInterval(refreshUserDataInterval)
        }*/
        // eslint-disable-next-line
    }, [])

    const getCategories = () => {
        getCategoriesByTopic().then((res) => {
            categoriesLink.setCategoriesState({ categories: res.content })
        })
    }

    useEffect(() => {
        if (!profileId) return
        const intervalAlive = setInterval(async () => {
            updateUserValues({ id: profileId, lastConnected: new Date().toISOString() })
        }, branding.presenceConfiguration.offlineAfterXMillis)

        appState.setIsMyHandRaised(false)
        appState.setMissedCallNotification(false, "", "")
        const abortController = new AbortController()

        if (profileId) {
            notificationContext.init(profileId)
            accessPresenceState.updateMyPresence(EventType.INIT)
        }
        return () => {
            if (profileId) {
                notificationContext.unsubscribe()
                clearInterval(intervalAlive)
            }
            abortController.abort()
        }
    }, [profileId]) //eslint-disable-line

    useEffect(() => {
        if (profileId) meetingInvitation.subscribeToCalls(profileId)
        // eslint-disable-next-line
    }, [profileId])

    if (!profileId) return null

    return (
        <AppSiteRoot lang={languageState.getLanguage()}>
            <MeetingProvider>
                {branding.configuration.useConferenceRoomV2 ? (
                    <HailingOverlayV2 audioFileFormat={audioFileFormat} />
                ) : (
                    <HailingOverlay audioFileFormat={audioFileFormat} />
                )}
                {chime.showConferenceOverlay() && <ConferenceOverlay />}

                {/* displaying PictureInPicture - real or dummy player */}
                <PictureInPictureVideoPlayer />

                {!isMobile && (
                    <SideIconBar
                        navToggle={(open) => setNavbarOpen(open)}
                        isNavOpen={navbarOpen}
                        myToggle={receptionRoute}
                        receptionPageToggle={(route) => setReceptionRoute(route)}
                        setTrigger={(value) => setTrigger(value)}
                    />
                )}
                <ContentAreaErrorBoundary>
                    {navbarOpen && <DarkenOverlay />}
                    {profileId && (
                        <ContentArea
                            profileId={profileId!}
                            changeRoute={(route) => setReceptionRoute(route)}
                            receptionRoute={receptionRoute}
                            trigger={trigger}
                            reloadScheduleList={reloadScheduleList}
                            setReloadScheduleList={setReloadScheduleList}
                        />
                    )}
                </ContentAreaErrorBoundary>

                {!isMobile && (
                    <>
                        <CommunicationArea
                            reloadScheduleList={reloadScheduleList}
                            setReloadScheduleList={setReloadScheduleList}
                        />
                        <NotificationCenter
                            receptionPageToggle={(route) => setReceptionRoute(route)}
                            setTrigger={(value) => setTrigger(value)}
                        />
                    </>
                )}
                {!branding.configuration.useConferenceRoomV2 && appState.isAudioVideoSettingsOpen && (
                    <Settings open={appState.isAudioVideoSettingsOpen} />
                )}
            </MeetingProvider>
        </AppSiteRoot>
    )
}

interface SiteProps {
    loggedIn: boolean
    dataPrivacyDoc: DataPrivacyDoc
    loadingStatus: AppLoadingStatus
}

function Site(props: SiteProps) {
    const userLink = useLoggedInState()
    const profileId = userLink.user()?.profileId || ""
    const [userEdited, setUserEdited] = useState<boolean>(isUserEdited(userLink))
    const favoriteState = useFavoriteState()

    useEffect(() => {
        setUserEdited(isUserEdited(userLink))
        // eslint-disable-next-line
    }, [profileId])

    useEffect(() => {
        //if an unregistered user tries to enter a link to a page inside the app before logging in, we redirect them to the home page

        if (userLink.isLoggedIn) {
            syncFavorites(favoriteState, userLink?.user()?.profileId!)
        }
        // eslint-disable-next-line
    }, [userLink.isLoggedIn, userLink.sessionAndTicketValid])

    if (!userLink.sessionVerificationDone) return <CenteredLoader />
    if (userLink.isLoggedIn && userLink.sessionAndTicketValid) {
        if (!userEdited && branding.userRegistrationSite.enabled) {
            return <UserRegistrationSite setUserEdited={setUserEdited} />
        } else {
            return <AppSite />
        }
    }
    return <LoginSite dataPrivacyDoc={props.dataPrivacyDoc} loadingStatus={props.loadingStatus} />
}

const StyledTransitionGroup = styled(TransitionGroup)`
    position: relative;
    width: 100%;
    height: 100%;
`

export enum AppLoadingStatus {
    LOADING,
    SUCCESS,
    FAILURE
}

interface AppProps {}
const App: React.FC<AppProps> = (props) => {
    const { isMobile } = useWindowDimensions()
    const userLink = useLoggedInState()
    const languageState = useLanguageState()
    const [dataPrivacy, setDataPrivacy] = useState<DataPrivacyDoc | undefined>(undefined)
    const [loadingStatus, setLoadingStatus] = useState<AppLoadingStatus>(AppLoadingStatus.LOADING)
    const alertState = useAlertState()

    useEffect(() => {
        ;(async () => {
            try {
                //if (localStorage.getItem("loadingInProcess")) return
                startLoading()
                if (!userLink.jwtToken()) {
                    const resp = await createAppDevice()
                    if ((resp as BackendServiceError).httpStatus) {
                        setLoadingStatus(AppLoadingStatus.FAILURE)
                        return
                    } else {
                        const tokenResp = resp as TokenResponse
                        userLink.updateToken(tokenResp.beConnectionToken)
                    }
                }

                if (!userLink.isLoggedIn || !userLink.sessionAndTicketValid) {
                    const resp = await getDataPrivacyDocs()
                    if ((resp as BackendServiceError).httpStatus) {
                        setLoadingStatus(AppLoadingStatus.FAILURE)
                        return
                    } else {
                        setDataPrivacy(resp as DataPrivacyDoc)
                    }
                }

                stopLoading()
                setLoadingStatus(AppLoadingStatus.SUCCESS)
            } catch {
                setLoadingStatus(AppLoadingStatus.FAILURE)
            }
        })()
    }, [languageState.getLanguage(), userLink.jwtToken()]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <ThemeProvider theme={lightTheme}>
            <StyledTransitionGroup style={{ position: isMobile ? "fixed" : "relative" }}>
                <Suspense fallback={<CenteredLoader></CenteredLoader>}>
                    {alertState.isAlertActive() && <Alert />}
                    <MeetingProvider>
                        <Site loggedIn={userLink.isLoggedIn} dataPrivacyDoc={dataPrivacy!} loadingStatus={loadingStatus} />
                    </MeetingProvider>
                </Suspense>
            </StyledTransitionGroup>
        </ThemeProvider>
    )
}

export default App
