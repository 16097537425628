export const replaceKickOrBanReasonPlaceholderText = (template: string, reason: string, defaultText: string) => {
    //var template = branding.globalTexts.guestRestrictionMessage
    let contentString

    if (reason !== "") {
        contentString = template.split("{$reason}").join(reason)
    } else {
        contentString = defaultText
    }

    return contentString
}

export function sortAlphabeticallyIgnoreCase(a: string, b: string) {
    // ignore upper and lowercase
    const wordA = a.toUpperCase()
    const wordB = b.toUpperCase()
    if (wordA < wordB) {
        return -1
    }
    if (wordA > wordB) {
        return 1
    }
    // names must be equal
    return 0
}
