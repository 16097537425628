import FileSaver from "file-saver"
import { useState } from "react"
import * as React from "react"
import AwesomeSlider from "react-awesome-slider"
import { Col, Row, Tooltip } from "react-bootstrap"
import ReactDOM from "react-dom"
import ReactPlayer from "react-player"
import styled from "styled-components"
import { hasAccessToOrganization } from "../../../utils/UserUtils"
import { trackVisit } from "../../../backendServices/TrackingServices"
import { Attachment, Company } from "../../../backendServices/Types"
import branding from "../../../branding/branding"
import { useLoggedInState, User } from "../../../globalStates/LoggedInUser"
import {
    IconArrowLeft,
    IconArrowRight,
    IconArrowRightCarousel,
    IconArrowLeftCarousel,
    IconClose,
    IconDownload,
    IconPlay
} from "../../../ui/Icons"
import { usePrivacyPolicyModal } from "../../../ui/modals/PrivacyPolicyModal"
import { HeaderTitle } from "./StyledComponents"
import { SimpleOrganization } from "../../ExhibitorsPageContent"
import NonGalleryMedia from "./NonGalleryMedia"
import { DesktopVersionContainer, device, MobileVersionContainer } from "../../../utils/Device"
import { CustomOverlayTrigger } from "../../../ui/CustomOverlayTrigger"

enum GalleryContentTypes {
    JPG = "jpg",
    JPEG = "jpeg",
    PNG = "png",
    MP4 = "mp4",
    GIF = "gif",
    BMP = "bmp",
    MOV = "mov"
}

export enum GalleryTypes {
    VIDEO = "video",
    IMAGE = "image"
}

function isGalleryType(value: string | null) {
    return (
        value === GalleryTypes.VIDEO ||
        value === GalleryTypes.IMAGE ||
        value === GalleryContentTypes.JPEG ||
        value === GalleryContentTypes.JPG ||
        value === GalleryContentTypes.PNG ||
        value === GalleryContentTypes.MP4 ||
        value === GalleryContentTypes.BMP ||
        value === GalleryContentTypes.GIF ||
        value === GalleryContentTypes.MOV
    )
}

function isVideo(value: string | null) {
    return value === GalleryTypes.VIDEO || value === GalleryContentTypes.MP4 || value === GalleryContentTypes.MOV
}

export const getContentType = (item: Attachment) => {
    const contentTypeTemp = item.contentType ? item.contentType.substring(0, item.contentType.lastIndexOf("/")) : null
    const contentType = contentTypeTemp
        ? contentTypeTemp
        : item.url
        ? item.url.substring(item.url.lastIndexOf(".") + 1, item.url.length)
        : null
    return contentType
}

export const filterMedia = (items: Attachment[], galleryType: boolean): Attachment[] => {
    if (items && items.length > 0) {
        return items.filter((x) => {
            if (!x.url) return false

            const contentType = getContentType(x)
            return (isGalleryType(contentType) && galleryType) || (!isGalleryType(contentType) && !galleryType)
        })
    }
    return []
}

const GalleryItemRoot = styled.div`
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: relative;

    video {
        object-fit: cover;
        border-radius: 5px;
    }

    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 5px;
    }
`
const DarkenOverlay = styled.div`
    display: flex;
    position: absolute;
    background: linear-gradient(180deg, rgba(32, 36, 40, 0) 0%, #000000 100%);
    width: 100%;
    height: 100%;
    border-radius: 5px;
    z-index: 0;
    top: 0;
    right: 0;

    & > * {
        flex-shrink: 0;
    }
`
const MediaTitleRoot = styled.div`
    display: flex;
    flex-flow: column;
    position: absolute;
    bottom: 20px;
    left: 20px;
    color: white;
    z-index: 1;

    & ::selection {
        background: transparent;
    }
    & ::-moz-selection {
        background: transparent;
    }
`
const MediaTitle = styled.div`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-wrap: normal;
    max-width: 220px;
    font-size: 16px;
    font-weight: 500;
    line-height: 17px;
    color: white;
    font-family: ${branding.font1};

    @media (max-width: 1400px) {
        max-width: 70px;
        font-size: 14px;
    }

    @media ${device.mobile} {
        font-size: 12px;
    }

    &.forceMobileDesign {
        font-size: 12px;
    }
`

const PlayIcon = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`

interface GalleryItemProps {
    media: Attachment
    index: number
    onAction: () => void
}

const GalleryItem: React.FunctionComponent<GalleryItemProps> = (props) => {
    const contentType = props.media.contentType
        ? props.media.contentType.substring(0, props.media.contentType.lastIndexOf("/"))
        : props.media.url.substring(props.media.url.lastIndexOf(".") + 1, props.media.url.length)
    const tooltipMessage = branding.organizationDetailPageContent.showFullScreen

    if (
        contentType !== GalleryTypes.VIDEO &&
        contentType !== GalleryContentTypes.MP4 &&
        contentType !== GalleryContentTypes.MOV &&
        contentType !== GalleryContentTypes.GIF
    ) {
        return (
            <CustomOverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={
                    <Tooltip id="tooltip" style={{ fontFamily: branding.font1 }}>
                        {tooltipMessage}
                    </Tooltip>
                }
            >
                <GalleryItemRoot onClick={() => props.onAction()}>
                    <img src={props.media.url} alt={""}></img>
                    <DarkenOverlay />
                    <MediaTitleRoot>
                        <MediaTitle>{props.media.title}</MediaTitle>
                    </MediaTitleRoot>
                </GalleryItemRoot>
            </CustomOverlayTrigger>
        )
    } else {
        return (
            <CustomOverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={
                    <Tooltip id="tooltip" style={{ fontFamily: branding.font1 }}>
                        {tooltipMessage}
                    </Tooltip>
                }
            >
                <GalleryItemRoot onClick={() => props.onAction()}>
                    <PlayIcon>{IconPlay({ width: "40", height: "40", fill: "#fff" })}</PlayIcon>
                    <ReactPlayer
                        width={"100%"}
                        height={"100%"}
                        url={props.media.url}
                        light={
                            props.media.largeThumbnailUrl
                                ? props.media.largeThumbnailUrl
                                : props.media.smallThumbnailUrl
                                ? props.media.smallThumbnailUrl
                                : false
                        }
                        config={{
                            file: {
                                attributes: {
                                    controlsList: "nodownload",
                                    onContextMenu: (e: any) => e.preventDefault()
                                }
                            }
                        }}
                    />
                    <DarkenOverlay />
                    <MediaTitleRoot>
                        <MediaTitle>{props.media.title}</MediaTitle>
                    </MediaTitleRoot>
                </GalleryItemRoot>
            </CustomOverlayTrigger>
        )
    }
}

const GalleryItemCol = styled(Col)`
    height: 300px;
    padding: 10px;

    @media (max-width: 1600px) {
        height: 200px;
    }

    @media ${device.mobile} {
        height: 95px;
        padding: 3px;
    }
`

interface GalleryProps {
    media: Attachment[]
    organizationId?: string
    onAction: (att: Attachment) => void
}

const GalleryRow = styled(Row)`
    margin-left: -3px;
    margin-right: -3px;

    @media ${device.tablet} {
        margin-left: -15px;
        margin-right: -15px;
    }
`

export const Gallery: React.FunctionComponent<GalleryProps> = (props) => {
    const logedInUser = useLoggedInState().user()
    const isQuest = !props.organizationId || hasAccessToOrganization(logedInUser!, props.organizationId) === false

    //making grid for gallery
    let x = [3, 6, 3, 6, 3, 3, 3, 3, 6]
    let colValues: Array<number> = []

    props.media.forEach((item, index) => {
        colValues.push(x[index % 9])
    })

    return (
        <GalleryRow>
            {props.media.map((item, index) => {
                return (
                    <GalleryItemCol
                        disable={isQuest}
                        key={index}
                        xs={colValues[index]}
                        onContextMenu={(e: { preventDefault: () => any }) => {
                            if (isQuest) e.preventDefault()
                        }}
                    >
                        <GalleryItem media={item} index={index} onAction={() => props.onAction(item)} />
                    </GalleryItemCol>
                )
            })}
        </GalleryRow>
    )
}
export interface MediaProps {
    media: Attachment[]
    organizationId?: string
    organization?: Company
    entityType?: string
}

const MediaContainer = styled.div`
    width: 100% !important;
    margin-left: 25px;
    margin-right: 25px;

    @media ${device.tablet} {
        margin-left: 20px;
    }
`
const NonGalleryMediaRoot = styled.div<{ marginTop?: string }>`
    margin-top: "0.5rem";
    @media ${device.tablet} {
        margin-top: ${(props) => (props.marginTop ? props.marginTop : "0.5rem")};
    }
`

export const Media: React.FunctionComponent<MediaProps> = (props) => {
    const user = useLoggedInState().user()
    const { showPrivacyPolicyModal, PrivacyModal } = usePrivacyPolicyModal()
    /* Media Slider */
    const [showMediaSlider, setShowMediaSlider] = useState(false)
    const [selectedAtt, setSelecedAtt] = useState<Attachment>()

    // Exporting media from attachments
    const galleryMedia = filterMedia(props.media, true)
    const otherMedia = filterMedia(props.media, false)

    const mediaItems: Array<any> = []

    galleryMedia.forEach((item) => {
        mediaItems.push({
            src: item.url,
            width: 4,
            height: 3
        })
    })

    const titleHeader =
        branding.organizationDetailPageContent.mediaTitle +
        " (" +
        (props.entityType && props.entityType === "product" ? otherMedia.length : props.media.length) +
        ")"

    if (!props.media || props.media.length === 0) return null

    return (
        <MediaContainer>
            {(props.entityType && props.entityType === "product" ? otherMedia.length > 0 : props.media.length > 0) && (
                <HeaderTitle className={props.entityType === "product" ? "product" : "mb-3"}>{titleHeader}</HeaderTitle>
            )}

            {props.entityType && props.entityType === "product" ? (
                <div />
            ) : (
                <Gallery
                    media={galleryMedia}
                    organizationId={props.organizationId}
                    onAction={(att) => {
                        if (props.organizationId && props.organization)
                            showPrivacyPolicyModal(props.organization, () => {
                                trackVisit(user!.profileId, props.organizationId!, "MEDIA#PREVIEW", att.id)
                                setSelecedAtt(att)
                                setShowMediaSlider(true)
                            })
                        else {
                            setSelecedAtt(att)
                            setShowMediaSlider(true)
                        }
                    }}
                />
            )}

            <NonGalleryMediaRoot
                className="col-12"
                marginTop={props.entityType && props.entityType === "product" ? "0rem" : "0.5rem"}
            >
                <NonGalleryMedia
                    media={otherMedia}
                    marginTop={props.entityType && props.entityType === "product" ? "0rem" : "1.25rem"}
                />
            </NonGalleryMediaRoot>
            <PrivacyModal />
            {ReactDOM.createPortal(
                showMediaSlider && (
                    <div>
                        <DesktopVersionContainer>
                            <MediaSlider
                                organizationId={props.organizationId}
                                media={props.media}
                                selectedAtt={selectedAtt}
                                setShowMediaSlider={setShowMediaSlider}
                            />
                        </DesktopVersionContainer>
                        <MobileVersionContainer>
                            <MediaSliderForMobileView
                                organizationId={props.organizationId}
                                media={props.media}
                                selectedAtt={selectedAtt}
                                setShowMediaSlider={setShowMediaSlider}
                            />
                        </MobileVersionContainer>
                    </div>
                ),
                document.body
            )}
        </MediaContainer>
    )
}

/* #region  Media slider */
const MediaSliderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 100;
    height: 100%;

    .awssld img,
    .awssld video {
        object-fit: contain;
    }

    .awssld__bullets {
        bottom: 0px;
        z-index: 10;
    }

    .awssld__bullets button {
        width: 5px;
        height: 5px;
    }

    .awssld__controls {
        visibility: visible !important;
    }

    .awssld__controls button {
        width: 20px !important;
        height: 20px !important;
        top: 50% !important;
    }
`

const CenteredDiv = styled.div`
    width: 75%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media (max-width: 1400px) {
        width: 100%;
    }
`

const DescriptionDownloadRoot = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 1px;
    width: 100%;
    padding: 15px;

    &.customDescriptionForMobileView {
        margin-top: 0px !important;
        padding: 10px 0px 10px 0px !important;
    }
`

const MediaDescriptionDiv = styled.div`
    width: 60%;
    color: ${branding.organizationDetailPageContent.mediaSliderPrimaryColor};
    font-family: ${branding.font1};
`

const MediaTitleSlider = styled.p`
    font-family: ${branding.font1};
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;

    &.customMediaTitleForMobileView {
        margin-bottom: 0px !important;
    }
`

const MediaDescription = styled.p`
    font-family: ${branding.font1};
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    &.customMediaDescriptionForMobileView {
        margin-bottom: 0px !important;
        margin-top: 5px !important;
    }
`

const Arrow = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    cursor: pointer;
`

const SideBar = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px 30px;
    width: 120px;
    height: 100%;
    color: ${branding.organizationDetailPageContent.mediaSliderPrimaryColor};
`
const LeftSideBar = styled(SideBar)`
    align-items: flex-start;
`

const RightSideBar = styled(SideBar)`
    align-items: flex-end;
`

const CloseIconDiv = styled.div`
    cursor: pointer;
`

const Placeholder = styled.div``
const DownloadButtonDiv = styled.div`
    cursor: pointer;
`
const MediaSliderCounter = styled.div`
    font-size: 20px;
    font-family: ${branding.font1};
    @media (max-width: 1400px) {
        font-size: 16px;
    }

    @media (max-width: 1200px) {
        font-size: 14px;
    }

    &.customForMobile {
        position: relative;
        margin-top: 10px;
        z-index: 10;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: rgba(32, 36, 40, 0.5);
    }
`

const IconWrapper = styled.div`
    &::before {
        content: none;
    }
`

interface MediaSliderProps {
    organizationId?: string
    organization?: Company
    media: Attachment[]
    selectedAtt: Attachment | undefined
    setShowMediaSlider: (value: boolean) => void
}

export const MediaSlider: React.FunctionComponent<MediaSliderProps> = (props) => {
    const filteredMedia = filterMedia(props.media, true)
    const [selected, setSelected] = useState(
        props.selectedAtt ? filteredMedia.findIndex((x) => x.url === props.selectedAtt!.url) : 0
    )
    const loggedInUser = useLoggedInState().user()
    let data = filteredMedia
        .map((item) => {
            let source = null
            var type = item.url.split(".").pop()
            if (isGalleryType(type!)) source = item.url
            if (!source && item.contentType) {
                var contentType = item.contentType
                    ? item.contentType.substring(item.contentType.lastIndexOf("/") + 1, item.contentType.length)
                    : null
                var contentTypeBefore = item.contentType.substring(0, item.contentType.lastIndexOf("/"))
                source = contentTypeBefore === GalleryTypes.VIDEO ? item.url + ".mp4" : item.url + "." + contentType
            }

            return {
                source: source
            }
        })
        .filter((x) => x.source != null)

    function changeMediaSource(element: any) {
        var mediaItem
        if (element) {
            mediaItem = element.firstChild as HTMLImageElement
            mediaItem.src = filteredMedia[selected].url
            mediaItem.setAttribute("controlsList", "nodownload")
            mediaItem.setAttribute("oncontextmenu", "return false;")
        }
    }

    return (
        <MediaSliderContainer>
            <LeftSideBar>
                <CloseIconDiv onClick={() => props.setShowMediaSlider(false)}>
                    {IconClose({ fill: branding.sideIconBar.sideIconColorDark, width: "15", height: "15" })}
                </CloseIconDiv>
                <Arrow onClick={() => setSelected(selected - 1)} hidden={selected === 0}>
                    {IconArrowLeft({ fill: branding.sideIconBar.sideIconColorDark })}
                </Arrow>
                <Placeholder />
            </LeftSideBar>
            <CenteredDiv>
                <AwesomeSlider
                    className="controlsList nodownload"
                    media={data}
                    selected={selected}
                    buttons={false}
                    bullets={false}
                    animation="scaleOutAnimation"
                    onFirstMount={() => {
                        changeMediaSource(document.getElementsByClassName("awssld__content")[0])
                    }}
                    onTransitionEnd={() => {
                        changeMediaSource(document.querySelector(".awssld--active .awssld__content"))
                    }}
                />
                <DescriptionDownloadRoot>
                    <MediaDescriptionDiv>
                        <MediaTitleSlider>{filteredMedia[selected]?.title}</MediaTitleSlider>
                        <MediaDescription>{filteredMedia[selected]?.description}</MediaDescription>
                    </MediaDescriptionDiv>
                    {(!isVideo(
                        filteredMedia[selected]?.contentType?.substring(0, filteredMedia[selected].contentType?.lastIndexOf("/"))
                    ) ||
                        (isVideo(
                            filteredMedia[selected]?.contentType?.substring(
                                0,
                                filteredMedia[selected]?.contentType?.lastIndexOf("/")
                            )
                        ) &&
                            branding.organizationDetailPageContent.videoDownloadEnabled)) && (
                        <DownloadButtonDiv
                            onClick={() => startDownloadProcess(filteredMedia[selected], loggedInUser!, props.organization)}
                        >
                            {IconDownload({ fill: branding.sideIconBar.sideIconColorDark })}
                        </DownloadButtonDiv>
                    )}
                </DescriptionDownloadRoot>
            </CenteredDiv>
            <RightSideBar>
                <MediaSliderCounter>
                    {selected + 1} / {filteredMedia.length}
                </MediaSliderCounter>
                <Arrow onClick={() => setSelected(selected + 1)} hidden={selected === filteredMedia.length - 1}>
                    {IconArrowRight({ fill: branding.sideIconBar.sideIconColorDark })}
                </Arrow>
                <Placeholder />
            </RightSideBar>
        </MediaSliderContainer>
    )
}

export const MediaSliderForMobileView: React.FunctionComponent<MediaSliderProps> = (props) => {
    const filteredMedia = filterMedia(props.media, true)
    const [selected, setSelected] = useState(
        props.selectedAtt ? filteredMedia.findIndex((x) => x.url === props.selectedAtt!.url) : 0
    )
    const loggedInUser = useLoggedInState().user()
    let data = filteredMedia
        .map((item) => {
            let source = null
            var type = item.url.split(".").pop()
            if (isGalleryType(type!)) source = item.url
            if (!source && item.contentType) {
                var contentType = item.contentType
                    ? item.contentType.substring(item.contentType.lastIndexOf("/") + 1, item.contentType.length)
                    : null
                var contentTypeBefore = item.contentType.substring(0, item.contentType.lastIndexOf("/"))
                source = contentTypeBefore === GalleryTypes.VIDEO ? item.url + ".mp4" : item.url + "." + contentType
            }

            return {
                source: source
            }
        })
        .filter((x) => x.source != null)

    function changeMediaSource(element: any) {
        var mediaItem
        if (element) {
            mediaItem = element.firstChild as HTMLImageElement
            mediaItem.src = filteredMedia[selected].url
            mediaItem.setAttribute("controlsList", "nodownload")
            mediaItem.setAttribute("oncontextmenu", "return false;")
        }
    }

    return (
        <MediaSliderContainer>
            <LeftSideBar style={{ width: "0px", padding: "20px 20px" }} />
            <CenteredDiv>
                <DescriptionDownloadRoot className="customDescriptionForMobileView">
                    <MediaDescriptionDiv>
                        <MediaTitleSlider className="customMediaTitleForMobileView">
                            {filteredMedia[selected]?.title}
                        </MediaTitleSlider>
                        {filteredMedia[selected]?.description && (
                            <MediaDescription className="customMediaDescriptionForMobileView">
                                {filteredMedia[selected]?.description}
                            </MediaDescription>
                        )}
                    </MediaDescriptionDiv>
                    {(!isVideo(
                        filteredMedia[selected]?.contentType?.substring(0, filteredMedia[selected].contentType?.lastIndexOf("/"))
                    ) ||
                        (isVideo(
                            filteredMedia[selected]?.contentType?.substring(
                                0,
                                filteredMedia[selected]?.contentType?.lastIndexOf("/")
                            )
                        ) &&
                            branding.organizationDetailPageContent.videoDownloadEnabled)) && (
                        <DownloadButtonDiv
                            onClick={() => startDownloadProcess(filteredMedia[selected], loggedInUser!, props.organization)}
                            style={{
                                marginTop: filteredMedia[selected]?.description === "" ? "-3px" : "20px"
                            }}
                        >
                            {IconDownload({ fill: branding.sideIconBar.sideIconColorDark, width: "15px", height: "15px" })}
                        </DownloadButtonDiv>
                    )}
                </DescriptionDownloadRoot>
                <AwesomeSlider
                    className="controlsList nodownload aws-slider-mobile"
                    media={data}
                    selected={selected}
                    bullets={false}
                    organicArrows={false}
                    buttonContentRight={
                        <IconWrapper onClick={() => setSelected(selected + 1)} hidden={selected === filteredMedia.length - 1}>
                            {IconArrowRightCarousel({ width: "18px", height: "18px", fill: "#D9D9D9" })}
                        </IconWrapper>
                    }
                    buttonContentLeft={
                        <IconWrapper onClick={() => setSelected(selected - 1)} hidden={selected === 0}>
                            {IconArrowLeftCarousel({ width: "18px", height: "18px", fill: "#D9D9D9" })}
                        </IconWrapper>
                    }
                    animation="scaleOutAnimation"
                    onFirstMount={() => {
                        changeMediaSource(document.getElementsByClassName("awssld__content")[1])
                    }}
                    onTransitionEnd={() => {
                        changeMediaSource(document.querySelector(".aws-slider-mobile .awssld--active .awssld__content"))
                    }}
                />
                <MediaSliderCounter className="customForMobile">
                    {selected + 1}/{filteredMedia.length}
                </MediaSliderCounter>
            </CenteredDiv>
            <RightSideBar style={{ padding: "20px 20px", width: "0px" }}>
                <CloseIconDiv onClick={() => props.setShowMediaSlider(false)}>
                    {IconClose({ width: "15", height: "15", fill: branding.sideIconBar.sideIconColorDark })}
                </CloseIconDiv>
            </RightSideBar>
        </MediaSliderContainer>
    )
}

/* #endregion */

function startDownloadProcess(attachment: Attachment, loggedInUser: User, organization?: SimpleOrganization) {
    if (attachment) {
        const fileName = attachment.title
        FileSaver.saveAs(attachment.url, fileName)
        if (organization?.id) trackVisit(loggedInUser.profileId, organization?.id, "MEDIA#DOWNLOAD", attachment.id)
    }
}
