import * as React from "react"
import styled from "styled-components"
import branding from "../branding/branding"
import { AvatarWithDefault } from "../ui/AvatarWithDefault"
import { IconLock, IconTicket } from "../ui/Icons"
import { MessageRow, MessageElementContentDefault, AvatarWrapper } from "../communicationArea/ChatPageMessageList"
import moment from "moment"
import CrsMultiSwitch from "../ui/CrsMultiSwitch"
import { AccessProvider } from "../backendServices/Types"

const dummyMessages = [
    {
        author: "John Doe",
        content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmo",
        date: "2021-07-08 10:15"
    },
    {
        author: "John Doe 2",
        content:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        date: "2021-07-08 10:20"
    },
    {
        author: "John Doe 3",
        content:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        date: "2021-07-08 10:21"
    },
    {
        author: "John Doe 4",
        content:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        date: "2021-07-08 10:22"
    },
    {
        author: "John Doe 5",
        content: "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        date: "2021-07-08 11:30"
    }
]

const ChatDummyRoot = styled.div`
    position: relative;
    height: 100%;
    box-shadow: -2px 2px 5px #ccc;
`
const ChatContentRoot = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
`

const DummySwitcherRoot = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 15px 0 30px 0;
`

const DummyMessagesRoot = styled.div`
    width: 100%;
    padding: 10px;
    filter: blur(3px);

    &::after {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        left: 0;
        background: rgba(255, 255, 255, 0.8);
    }
`

const LockParent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 2;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
`

const LockIconParent = styled.div`
    width: 3vw;
    height: 3vw;
`
interface ChatDummyProps {
    accessProvider: AccessProvider
}
const ChatDummy: React.FC<ChatDummyProps> = (props) => {
    const className = "their"
    const dummySwitcherItems = [
        { label: branding.conferenceTexts.chatSwitch, value: "dummyChat" },
        { label: branding.conferenceTexts.participantsSwitch, value: "dummyParticipants" }
    ]

    const displayTicketIcon = props.accessProvider === AccessProvider.TICKET

    return (
        <ChatDummyRoot>
            <ChatContentRoot>
                <DummyMessagesRoot>
                    <DummySwitcherRoot>
                        <CrsMultiSwitch
                            items={dummySwitcherItems}
                            activeItem={"dummyChat"}
                            onItemClick={() => {}}
                            style={{ mainWidth: "70%" }}
                        />
                    </DummySwitcherRoot>
                    {dummyMessages.map((message, index) => {
                        return (
                            <MessageRow key={index} className={className + " withAvatar"}>
                                <AvatarWrapper>
                                    <AvatarWithDefault size={30} alt={message.author} backgroundSize="cover" />
                                </AvatarWrapper>
                                <MessageElementContentDefault
                                    className={className}
                                    content={message.content}
                                    authorName={message.author}
                                    timestamp={moment(message.date).toDate()}
                                />
                            </MessageRow>
                        )
                    })}
                </DummyMessagesRoot>
                <LockParent>
                    <LockIconParent>
                        {displayTicketIcon ? <IconTicket width="auto" height="auto" /> : <IconLock width="auto" height="auto" />}
                    </LockIconParent>
                    <div className="mt-3 w-75 text-center">{branding.chatBranding.chatBlockedForPrivateEventText}</div>
                </LockParent>
            </ChatContentRoot>
        </ChatDummyRoot>
    )
}

export default ChatDummy
