import { format } from "date-fns"
import de from "date-fns/locale/de"
import en from "date-fns/locale/en-GB"
import moment from "moment"
import * as React from "react"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import styled from "styled-components"
import { loadJobOfferData } from "../../backendServices/JobofferServices"
import { trackJobOfferPageVisit } from "../../backendServices/TrackingServices"
import { JobOffer } from "../../backendServices/Types"
import branding from "../../branding/branding"
import { useAppState } from "../../globalStates/AppState"
import { useLanguageState } from "../../globalStates/LanguageState"
import { useLoggedInState } from "../../globalStates/LoggedInUser"
import Breadcrumb from "../../navigationArea/Breadcrumb"
import { joboffersPageRoute, showfloorPageRoute } from "../../navigationArea/RoutePaths"
import TopBar from "../../navigationArea/TopBar"
import { useNavigationSource } from "../../tracking/NavigationSource"
import { calcBreadcrumbLocations } from "../../tracking/RouteTracker"
import BackendError from "../../ui/BackendError"
import CenteredLoader from "../../ui/CenteredLoader"
import CompanyDetails from "../../ui/CompanyDetails"
import { IconJobofferEmploymentType, IconJobofferHomeOffice, IconJobofferStart, IconLocation } from "../../ui/Icons"
import useWindowDimensions from "../../ui/WindowDimensionsHook"
import { device } from "../../utils/Device"
import GuestUserBanner from "../guestUserBanner/GuestUserBanner"
import GuestUserBannerSharedState from "../guestUserBanner/GuestUserBannerSharedState"
import DetailPageSections from "./components/DetailPageSections"
import { aspectRatio, DetailsRoot, ScrollContainerRoot } from "./components/StyledComponents"

const ContentWrapper = styled.div`
    & .company-wrapper {
        display: block;
    }

    @media ${device.tablet} {
        & .company-wrapper {
            display: none;
        }
    }
`

const JobOfferDetailPageContent: React.FunctionComponent = (props) => {
    let { jobofferId }: any = useParams()
    const [isLoaded, setIsLoaded] = useState(false)
    const [error, setError] = useState("")
    const [joboffer, setJoboffer] = useState<JobOffer>()
    const langState = useLanguageState()
    const lang = langState.getLanguage()
    const windowSize = useWindowDimensions()
    const appState = useAppState()
    const { guestUserBannerRef, setGuestUserBannerRef } = GuestUserBannerSharedState()
    const adjustHeight = (guestUserBannerRef && guestUserBannerRef.current ? guestUserBannerRef.current.clientHeight : 0) + 125
    const profileId = useLoggedInState().user()?.profileId
    const navi = useNavigationSource()

    useEffect(() => {
        appState.setCurrentMobileBreadcrumb(branding.globalSearchResultPage.jobofferEntityTitle)

        setIsLoaded(false)
        let params = {
            id: jobofferId
        }
        loadJobOfferData(params)
            .then((resp) => {
                setIsLoaded(true)
                setJoboffer(resp.content)
                trackJobOfferPageVisit(profileId!, resp.content.organization.id, navi.get(), resp.content.id)
            })
            .catch((e: { message: React.SetStateAction<string> }) => {
                setIsLoaded(true)
                setError(e.message)
            })
        // eslint-disable-next-line
    }, [jobofferId, lang])

    let content: JSX.Element = <div />
    const locations = calcBreadcrumbLocations()
    const breadcrumb = [
        { to: showfloorPageRoute, name: branding.sideIconBar.showfloorMenuText },
        { to: joboffersPageRoute, name: branding.exhibitorsPageContent.jobofferTabTitle }
    ]
    if (joboffer) {
        breadcrumb.push({ to: locations[1], name: joboffer.name })
    }

    if (error !== "") {
        content = (
            <div style={{ marginTop: "25%" }}>
                <BackendError />
            </div>
        )
    } else if (joboffer) {
        if (!isLoaded) {
            content = <CenteredLoader />
        }

        content = (
            <>
                <Breadcrumb breadcrumb={breadcrumb} />
                <ScrollContainerRoot>
                    {/* Main content */}
                    <DetailPageSections type="joboffer" entity={joboffer} adjustHeightWith={adjustHeight} />
                </ScrollContainerRoot>
            </>
        )
    } else {
        content = <CenteredLoader />
    }

    return (
        <DetailsRoot paddingBottom={aspectRatio} windowSize={windowSize.width} isNetworkingOpen={appState.isNetworkingOpen()}>
            <GuestUserBanner setRef={setGuestUserBannerRef} />
            <TopBar />
            <ContentWrapper>{content}</ContentWrapper>
        </DetailsRoot>
    )
}

export default JobOfferDetailPageContent

const DetailRoot = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    width: 100%;

    @media ${device.mobileS} {
        width: auto;
        padding: 0 15px;
    }

    @media ${device.tablet} {
        flex-direction: row;
        margin-top: 15px;
        padding: 0;
    }
`

const DetailColumn = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;

    line-height: 17px;
    font-size: 12px;
    font-weight: 400;
    font-style: normal;
    font-family: ${branding.font1};

    & p {
        margin-bottom: 0;
    }

    & a {
        font-weight: normal !important;
    }

    @media ${device.mobile} {
        margin-bottom: 15px;
        font-size: 14px;
        line-height: 24px;
    }
`
const DetailColumnIcon = styled.div`
    padding-right: 10px;
`

const DetailColumnValue = styled.div`
    padding-top: 4px;
`

const DescriptionWrapper = styled.div`
    margin-top: 25px;
`

const TextTitle = styled.div`
    padding: 25px 0;
    font-size: 14px;
    font-weight: bold;

    @media ${device.laptop} {
        font-size: 18px;
    }
`

const CompanyDetailsStyled = styled(CompanyDetails)`
    margin: 40px 0;
    display: none;

    @media ${device.tablet} {
        display: flex;
    }

    @media ${device.laptop} {
        margin: 20px 0 10px;
    }
`

const MultiLineText = styled.div`
    font-size: 14px;
    white-space: pre-line;

    @media ${device.desktop} {
        font-size: 16px;
    }
`

const MultiLineTextWrapper = (props: { text: string }) => {
    return (
        <MultiLineText>
            {props.text.split("\\n").map((item, i) => (
                <span key={i}>
                    {item}
                    <br />
                </span>
            ))}
        </MultiLineText>
    )
}

interface JobOfferDetailsProps {
    joboffer: JobOffer
}
export const JobOfferDetails: React.FunctionComponent<JobOfferDetailsProps> = (props) => {
    const { joboffer } = props
    const langState = useLanguageState()
    const lang = langState.getLanguage()
    const homeOfficeText =
        branding.jobofferDetailPageContent.homeOfficeTitle +
        (joboffer.homeOffice ? branding.systemCheck.yes : branding.systemCheck.no)

    const iconProps = { fill: branding.sideIconBar.sideIconColorDark, width: "20", height: "20" }

    return (
        <>
            <DetailRoot>
                {joboffer.location && (
                    <DetailColumn>
                        <DetailColumnIcon>{IconLocation(iconProps)}</DetailColumnIcon>
                        <DetailColumnValue>{joboffer.location}</DetailColumnValue>
                    </DetailColumn>
                )}
                {joboffer.employmentType && (
                    <DetailColumn>
                        <DetailColumnIcon>{IconJobofferEmploymentType(iconProps)}</DetailColumnIcon>
                        <DetailColumnValue>{joboffer.employmentType} </DetailColumnValue>
                    </DetailColumn>
                )}
                {joboffer.startdate && (
                    <DetailColumn>
                        <DetailColumnIcon>{IconJobofferStart(iconProps)}</DetailColumnIcon>
                        <DetailColumnValue>
                            {format(moment(joboffer.startdate).toDate(), branding.jobofferDetailPageContent.startDatePattern, {
                                locale: lang === "de" ? de : en
                            })}
                        </DetailColumnValue>
                    </DetailColumn>
                )}
                {joboffer.homeOffice !== undefined && (
                    <DetailColumn>
                        <DetailColumnIcon>{IconJobofferHomeOffice(iconProps)}</DetailColumnIcon>
                        <DetailColumnValue>{homeOfficeText}</DetailColumnValue>
                    </DetailColumn>
                )}
            </DetailRoot>
            {joboffer.organization?.id && <CompanyDetailsStyled organization={joboffer.organization} source="JOBOFFER" />}
            {joboffer.description?.text && (
                <DescriptionWrapper>
                    <TextTitle>{branding.jobofferDetailPageContent.descriptionTitle}</TextTitle>
                    <MultiLineTextWrapper text={joboffer.description?.text} />
                </DescriptionWrapper>
            )}
            {joboffer.requirements && (
                <DescriptionWrapper>
                    <TextTitle>{branding.jobofferDetailPageContent.requirementsTitle}</TextTitle>
                    <MultiLineTextWrapper text={joboffer.requirements} />
                </DescriptionWrapper>
            )}
        </>
    )
}
