import styled from "styled-components"
import branding from "../../../branding/branding"
import { NetworkingUser, Contact, ModalType } from "../../../backendServices/Types"
import { useState, useEffect } from "react"
import * as React from "react"
import { IconClose, IconLocation, IconArrowHeadRight } from "../../../ui/Icons"
import { Row, Col } from "react-bootstrap"
import { DetailNavLink } from "../../detailPages/DetailNavLink"
import { AvatarWithDefault } from "../../../ui/AvatarWithDefault"
import ContextMenu from "../../../ui/ContextMenu"
import { createActions } from "../../../communicationArea/CommunicationOptions"
import { useLoggedInState } from "../../../globalStates/LoggedInUser"
import { useFavoriteState } from "../../../globalStates/Favorites"
import { useAppState } from "../../../globalStates/AppState"
import { useMeetingContext } from "../../../conference/context/MeetingContext"
import { useChimeContext } from "../../../conference/context/ChimeContext"
import { useContactState } from "../../../communicationArea/ContactState"
import { Interest } from "../NetworkingPageContent"
import { viewType } from "../NetworkingPageContentBranding"
import { useRosterContext } from "../../../conference/context/RosterContext"
import { useMeetingInvitation } from "../../../conferenceV2/context/MeetingInvitation"
import { useMeetingController } from "../../../conferenceV2/context/MeetingController"

/*********************************************************************************************
 * NODE DETAIL POPUP STYLE
 **********************************************************************************************/

const NodeDetailRoot = styled.div<{ top: number; left: number }>`
    position: absolute;
    z-index: 10000;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: ${branding.networkingArea.nodeDetailRootBgColor};
    /* border: 1px solid ${branding.networkingArea.nodeDetailRootBorderColor}; */
    max-width: 390px;
    min-width: 390px;
    box-shadow: 0px 4px 10px ${branding.networkingArea.nodeDetailRootBoxShadowColor};
    border-radius: 5px;
    transition: top 0.5s;
`

const NodeDetailBody = styled.div`
    padding: 30px 30px 10px 30px;
`

const NodeDetailContent = styled.div`
    padding-right: 20px;
    padding-bottom: 5px;
    & .user-name {
        color: ${branding.networkingArea.nodeDetailContentUsernameTextColor};
        font-size: 16px;
        line-height: 17px;
        font-weight: bold;
        font-family: ${branding.font1};
    }
    & .title {
        margin-top: 5px !important;
        color: ${branding.networkingArea.nodeDetailContentTitleTextColor};
        margin: 0;
        font-size: 12px;
        line-height: 18px;
        font-family: ${branding.font1};
    }
`
const NodeDetailActionRoot = styled.div`
    text-align: center;
    border-top: 1px solid ${branding.networkingArea.nodeDetailContentInterestsBorder};
    margin-top: 15px;
`
const NodeDetailActionParagraph = styled.p`
    font-size: 12px;
    line-height: 14px;
    font-family: ${branding.font1};
    color: ${branding.networkingArea.nodeDetailContentTitleTextColor};
    margin-bottom: 0;
`
const InterestsTitleParagraph = styled.p`
    color: ${branding.networkingArea.nodeDetailContentInterestsTitleParagraph};
    font-size: 16px;
    line-height: 12px;
    padding: 0.4rem 0px 0.4rem 0px;
    font-family: ${branding.font1};
    font-style: normal;
    font-weight: 700;
    margin-bottom: 0.4rem;
`
const NodeDetailInterestsRoot = styled.div`
    position: relative;
    border-top: 1px solid ${branding.networkingArea.nodeDetailContentInterestsBorder};
    padding: 0.5rem 0;
    margin-top: 1rem;
    width: 100%;
`
const NodeDetailInterestsContent = styled.div`
    max-height: 150px;
    overflow-y: auto;
`
const NodeDetailInterestsItem = styled.div`
    display: inline-block;
    padding: 0.3rem 0.6rem;
    border-radius: 5px;
    background-color: ${branding.networkingArea.nodeDetailContentInterestsBackground};
    font-size: 12px;
    line-height: 17px;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    font-family: ${branding.font2};
    margin: 0.3rem 0.3rem 0.3rem 0rem;
    color: ${branding.networkingArea.nodeDetailContentInterestsTextColor};
`
const NodeDetailInterestsBottomGradient = styled.div`
    height: 15px;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    background: rgb(255, 255, 255);
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
`
const NodeDetailCloseBtn = styled.div`
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
`

const BottomActionsContainer = styled.div<{ showDelimiter: boolean }>`
    padding: 15px 0;
    display: flex;
    position: relative;
    color: ${branding.networkingArea.nodeDetailContentRemoveSuggestionTextColor};
    font-size: 0.75rem;
    font-family: ${branding.font1};
    font-weight: bold;
    cursor: pointer;
    text-align: center;
    border-top: 1px solid ${branding.networkingArea.nodeDetailRootBorderColor};
    &::after {
        content: "";
        display: ${(props) => (props.showDelimiter ? "block" : "none")};
        height: 100%;
        width: 1px;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        background-color: ${branding.networkingArea.nodeDetailRootBorderColor};
    }
`

const GoToProfilePageBtn = styled.div<{ halfWidth: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${(props) => (props.halfWidth ? "50%" : "100%")};
    padding: 0 10px;
`
const NodeDetailRemoveSuggestionBtn = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    padding: 0 10px;
`

interface NodeDetailPopupProps {
    selectedUser: string | undefined
    netChart: ZoomCharts.NetChart | undefined
    contact: Contact
    bookmarked: boolean
    currentViewType: viewType
    selectedUserInterest: string[]
    nodeDetailRootRef: React.RefObject<HTMLDivElement>
    allInterests: Interest[]
    onMenuCallback: (param: { modalType?: ModalType }) => void
    onNodeDetailHide: () => void
    onHideFromSuggestionClick: () => void
}

interface NodeDetailsInteres {
    id: string
    label: string
}

/*********************************************************************************************
 * NODE DETAIL USER INFO
 **********************************************************************************************/

export const UserInfo = (props: { userData: NetworkingUser }) => {
    const personPosition: string = [props.userData.position, props.userData.company]
        .filter(Boolean)
        .join(" " + branding.communicationArea.personCompanyLink + " ")

    return (
        <Row className="m-0 align-items-center">
            <Col xs={2} className={"p-0"}>
                <DetailNavLink
                    type="user"
                    id={props.userData.id}
                    name={`/person/${props.userData.firstName}_${props.userData.lastName}`}
                >
                    <AvatarWithDefault
                        size={45}
                        src={props.userData.logoUrl}
                        alt={[props.userData.firstName, props.userData.lastName].join(" ") ?? "#"}
                    />
                </DetailNavLink>
            </Col>
            <Col xs={10} className="pl-2 pr-1">
                <NodeDetailContent>
                    <DetailNavLink
                        type="user"
                        id={props.userData.id}
                        name={`/person/${props.userData.firstName}_${props.userData.lastName}`}
                    >
                        <p className={"user-name m-0 w-75"}>
                            {[props.userData.firstName, props.userData.lastName].filter(Boolean).join(" ")}
                        </p>
                        <p className={"title m-0 w-75"}>{personPosition}</p>
                    </DetailNavLink>
                </NodeDetailContent>
            </Col>
        </Row>
    )
}

/*********************************************************************************************
 * NODE DETAIL POPUP
 **********************************************************************************************/

const NodeDetailPopup = (props: NodeDetailPopupProps) => {
    const [selectedUserInterests, setSelectedUserInterests] = useState<Array<NodeDetailsInteres>>([])

    // Context menu
    let userState = useLoggedInState()
    const favoriteState = useFavoriteState()
    const appState = useAppState()
    const meeting = useMeetingContext()
    const chime = useChimeContext()
    const roster = useRosterContext()
    const contactState = useContactState()
    var connectionStatus = props.selectedUser ? contactState.getConnectionStatus(props.selectedUser) : "UNRELATED"
    const meetingInvitation = useMeetingInvitation()
    const meetingController = useMeetingController()
    const isReportButtonHidden = false

    useEffect(() => {
        setPersonInterest()
        // eslint-disable-next-line
    }, [props.selectedUser])

    // Helper functions
    const setPersonInterest = () => {
        if (userState.user() && props.allInterests) {
            let loggedInUserInterests = userState.user()!.interests
            let selectedPersonInterests: Array<NodeDetailsInteres> = []

            if (props.selectedUserInterest) {
                props.selectedUserInterest.forEach((interest) => {
                    const found = props.allInterests!.find((x) => x.id === interest)
                    const matchingInterest = loggedInUserInterests?.includes(interest)
                    if (found && matchingInterest) {
                        selectedPersonInterests.push({
                            id: interest,
                            label: found.name
                        })
                    }
                })
                setSelectedUserInterests(selectedPersonInterests)
            }
        }
    }

    if (!props.selectedUser || !props.netChart) return <div></div>

    let selectedNode = props.netChart.getNode(props.selectedUser)
    let selectedNodeDimensions = props.netChart!.getNodeDimensions(selectedNode)
    let userData = selectedNode.data as NetworkingUser

    return (
        <NodeDetailRoot
            ref={props.nodeDetailRootRef}
            top={selectedNodeDimensions!.y - selectedNodeDimensions!.hwidth - 20}
            left={selectedNodeDimensions!.x - selectedNodeDimensions!.hwidth - 20}
        >
            <NodeDetailBody>
                <NodeDetailCloseBtn onClick={props.onNodeDetailHide}>
                    {IconClose({ fill: branding.recommendModal.closeIconColor, width: "15", height: "15" })}
                </NodeDetailCloseBtn>
                <UserInfo userData={userData} />
                {userData.organizations && userData.organizations.length > 0 && (
                    <Row className="m-0 pl-2">
                        {userData.organizations[0].logo && (
                            <Col xs={3} className="offset-2 pl-1 pr-1 d-flex align-items-center">
                                <img src={userData.organizations[0].logo} style={{ width: "60%" }} alt="Company logo" />
                            </Col>
                        )}
                        {(userData.organizations[0].city || userData.organizations[0].country) && (
                            <Col xs={6} className={userData.organizations[0].logo ? "" : "offset-2"}>
                                <Row className={"d-flex align-items-center"}>
                                    <Col xs={2} style={{ marginBottom: "10px" }}>
                                        <NodeDetailActionParagraph>
                                            {IconLocation({ fill: branding.sideIconBar.sideIconColorDark })}
                                        </NodeDetailActionParagraph>
                                    </Col>
                                    <Col xs={9}>
                                        <NodeDetailActionParagraph>
                                            <span>{userData.organizations[0].city}</span>
                                            {userData.organizations[0].city && userData.organizations[0].country && (
                                                <span>,</span>
                                            )}
                                        </NodeDetailActionParagraph>
                                        <NodeDetailActionParagraph className={"m-0"}>
                                            {userData.organizations[0].country}
                                        </NodeDetailActionParagraph>
                                    </Col>
                                </Row>
                            </Col>
                        )}
                    </Row>
                )}
                {selectedUserInterests.length > 0 && (
                    <Row className="m-0">
                        <NodeDetailInterestsRoot>
                            <InterestsTitleParagraph>{branding.personDetailPageContent.interestsTitle}</InterestsTitleParagraph>
                            <NodeDetailInterestsContent>
                                {selectedUserInterests!.map((item, key) => {
                                    return <NodeDetailInterestsItem key={key}>{item.label}</NodeDetailInterestsItem>
                                })}
                            </NodeDetailInterestsContent>
                            <NodeDetailInterestsBottomGradient />
                        </NodeDetailInterestsRoot>
                    </Row>
                )}
                <NodeDetailActionRoot>
                    <ContextMenu
                        itemsPerRow={branding.networkingArea.nodeDetailActionsItemsPerRow}
                        collapsed={false}
                        items={() =>
                            createActions(
                                userState.user(),
                                props.contact,
                                favoriteState,
                                contactState,
                                appState,
                                meeting,
                                chime,
                                meetingInvitation,
                                meetingController,
                                isReportButtonHidden,
                                roster,
                                props.bookmarked,
                                connectionStatus,
                                props.onMenuCallback
                            )
                        }
                    />
                </NodeDetailActionRoot>
            </NodeDetailBody>
            <BottomActionsContainer showDelimiter={props.currentViewType === viewType.RELEVANT_PPL}>
                {props.currentViewType === viewType.RELEVANT_PPL && (
                    <NodeDetailRemoveSuggestionBtn onClick={props.onHideFromSuggestionClick}>
                        {branding.networkingArea.hideFromSuggestionsText}
                    </NodeDetailRemoveSuggestionBtn>
                )}
                <GoToProfilePageBtn halfWidth={props.currentViewType === viewType.RELEVANT_PPL}>
                    <DetailNavLink type="user" id={userData.id} name={`/person/${userData.firstName}_${userData.lastName}`}>
                        {branding.networkingArea.goToProfilePageText}{" "}
                        {IconArrowHeadRight({ fill: branding.sideIconBar.sideIconColorDark, width: "10", height: "10" })}
                    </DetailNavLink>
                </GoToProfilePageBtn>
            </BottomActionsContainer>
        </NodeDetailRoot>
    )
}

export default NodeDetailPopup
