import moment from "moment"
import queryString from "query-string"
import { useEffect, useRef, useState } from "react"
import * as React from "react"
import "react-awesome-slider/dist/custom-animations/scale-out-animation.css"
import "react-awesome-slider/dist/styles.css"
import { Col, Container, Row, Tooltip } from "react-bootstrap"
import { useHistory, useParams } from "react-router-dom"
import styled from "styled-components"
import { isUserEdited } from "../../App"
import { syncFavorites } from "../../backendServices/FavoritesServices"
import { Company, Person, ShareTargetType } from "../../backendServices/Types"
import { BasisPremiumType, getBasisPremiumConfig } from "../../branding/BasisPremiumBranding"
import branding from "../../branding/branding"
import { saveExhibitorVCard } from "../../communicationArea/VCard"
import { getExternalMeetingId, useChimeContext } from "../../conference/context/ChimeContext"
import { useMeetingContext } from "../../conference/context/MeetingContext"
import { useAppState } from "../../globalStates/AppState"
import { useFavoriteState } from "../../globalStates/Favorites"
import { getIamPartOf } from "../../globalStates/IAmPartOf"
import { useLanguageState } from "../../globalStates/LanguageState"
import { useLoggedInState, User } from "../../globalStates/LoggedInUser"
import Breadcrumb from "../../navigationArea/Breadcrumb"
import { exhibitorsPageRoute, showfloorPageRoute } from "../../navigationArea/RoutePaths"
import TopBar from "../../navigationArea/TopBar"
import { calcBreadcrumbLocations } from "../../tracking/RouteTracker"
import BackendError from "../../ui/BackendError"
import { CalendarEntryModalViewMode, useCalendarEntryModal } from "../../ui/modals/CalendarEntryModal"
import CenteredLoader from "../../ui/CenteredLoader"
import { useGuestModal } from "../../ui/modals/GuestModal"
import {
    IconBackoffice,
    IconBookmarkFilled,
    IconBookmarkToggle,
    IconCall,
    IconCameraJoin,
    IconDownloadVCard,
    IconEditCompanyProfile,
    IconMeetingSchedule,
    IconShare
} from "../../ui/Icons"
import { usePrivacyPolicyModal } from "../../ui/modals/PrivacyPolicyModal"
import { useRecommendModal } from "../../ui/modals/RecommendModal"
import { useSayHelloModal } from "../../ui/modals/SayHelloModal"
import useWindowDimensions from "../../ui/WindowDimensionsHook"
import { hasAccessToOrganization } from "../../utils/UserUtils"
import { DesktopVersionContainer } from "../../utils/Device"
import { isExplorationOrPostEventPhase, isLivePhase, isPostEventPhase } from "../../utils/EventPhaseChecker"
import GuestUserBanner from "../guestUserBanner/GuestUserBanner"
import GuestUserBannerSharedState from "../guestUserBanner/GuestUserBannerSharedState"
import { aspectRatio, aspectRatioShrinked, DetailsRoot, ScrollContainerRoot } from "./components/StyledComponents"
import { buildDetailLink } from "./DetailNavLink"
import DetailPageSections from "./components/DetailPageSections"
import { VideoModal } from "./OrgDetailHeaderVideo"
import { CustomOverlayTrigger } from "../../ui/CustomOverlayTrigger"
import {
    ExhibitorDetailsParams,
    getOrganizationContentManagerlink,
    loadExhibitorData
} from "../../backendServices/ExhibitorServices"
import { BackendServiceError } from "../../backendServices/BackendServicesUtils"
import { getUserOrgaInfo } from "../../backendServices/SeriesOfTopicsUserServices"
import { AttendeeData, listAttendeeInfos } from "../../backendServices/MeetingServices"
import { trackVisit } from "../../backendServices/TrackingServices"
import TopBannerSharedState from "../advertisingBanner/TopBannerSharedState"
import { MeetingInvitation, useMeetingInvitation } from "../../conferenceV2/context/MeetingInvitation"
import { generateMeetingURL } from "../../conferenceV2/utils/generateMeetingURL"
import { PresenceContext, usePresenceState } from "../../ui/PresenceIndicator"
import { PresenceType } from "../../backendServices/GraphQLServices"
import { useStaffMembersModal } from "../../ui/modals/NoStaffMembersModal"
import { InvitationType } from "../../API"

/*********************************************************************************************
 * site assembly
 **********************************************************************************************/
const HeaderSticky = styled.div<{ paddingBottom: string }>`
    position: relative;
    padding-bottom: ${(props) => props.paddingBottom};
    transition: 0.5s;

    &::after {
        content: "";
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        bottom: 0;
        left: 0;
        right: 0;
        background: ${(props) =>
            props.paddingBottom === aspectRatioShrinked
                ? "linear-gradient(180deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, .5))"
                : "linear-gradient(180deg,  rgba(255, 255, 255, .4) 70%, rgba(0, 0, 0, .6) 85%)"};
        opacity: 0.5;
        pointer-events: none;
        transition: opacity 0.5s;

        @media (max-width: 1700px) {
            background: ${(props) =>
                props.paddingBottom === aspectRatioShrinked
                    ? "linear-gradient(180deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, .5))"
                    : "linear-gradient(180deg,rgba(255, 255, 255, .4) 70%, rgba(0, 0, 0, .6) 85%)"};
        }

        @media (max-width: 1500px) {
            background: ${(props) =>
                props.paddingBottom === aspectRatioShrinked
                    ? "linear-gradient(180deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, .5))"
                    : "linear-gradient(180deg, rgba(255, 255, 255, .4) 70%, rgba(0, 0, 0, .6) 85%)"};
        }

        @media (max-width: 1300px) {
            background: ${(props) =>
                props.paddingBottom === aspectRatioShrinked
                    ? "linear-gradient(180deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, .5))"
                    : "linear-gradient(180deg, rgba(255, 255, 255, .4) 70%, rgba(0, 0, 0, .6) 85%)"};
        }

        @media (max-width: 1300px) {
            background: linear-gradient(180deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.5));
        }
    }
`

const HeaderRoot = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: left top;
    }
`

/* #region  Company header */
const CompanyHeaderContainer = styled(Container)`
    z-index: 5;
`

const CompanyHeaderRoot = styled.div`
    position: absolute;
    bottom: 10px;
    width: 70%;
    left: 15%;
    z-index: 5;
`

const CompanyHeaderRow = styled(Row)`
    justify-content: center;
`

const CompanyHeaderCol = styled(Col)<{ iconVisible: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-family: ${branding.font1};
    flex-direction: column;
    cursor: pointer;
    pointer-events: ${(props) => (props.iconVisible ? "auto" : "none")};
    opacity: ${(props) => (props.iconVisible ? 1 : 0.4)};
`

const CompanyHeaderLink = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff !important;
    flex-direction: column;
    cursor: pointer;
    font-family: ${branding.font1};
    text-decoration: none !important;
    z-index: 120px;
`

const HeaderItemsTitle = styled.div`
    font-size: 12px;
    text-align: center;
    font-family: ${branding.font1};
    white-space: pre-line;
    height: 50px;

    @media (max-width: 1200px) {
        display: none;
        height: 0px;
    }
`

const HeaderIconDiv = styled.div`
    cursor: pointer;

    @media (min-width: 1200px) {
        margin-bottom: 5px !important;
    }
`

const StyledTooltip = styled(Tooltip)`
    @media (min-width: 1200px) {
        display: none;
    }
`

interface CompanyHeaderProps {
    isStaff: boolean
    organizationId: string
    organizationName: string
    exhibitor?: Company
    isBookmarked?: boolean
}

function CompanyHeader(props: CompanyHeaderProps) {
    const { showPrivacyPolicyModal, PrivacyModal } = usePrivacyPolicyModal()
    const history = useHistory()
    const chime = useChimeContext()
    const meeting = useMeetingContext()
    const { showRecommendModal: showRecommendOrganizationModal, RecommendModal: OrganizationModal } = useRecommendModal()
    const { showCalendarEntryModal, CalendarModal } = useCalendarEntryModal()
    const { showGuestModal, GuestModal } = useGuestModal()
    const { showNoStaffMembersModal, NoStaffMembersModal } = useStaffMembersModal()
    const { showSayHelloModal, SayHelloModal } = useSayHelloModal()
    const queryParams: any = queryString.parse(window.location.search)
    //isBookmarked
    const userState = useLoggedInState()
    const loggedInUserId = userState.user()?.profileId
    const favorites = useFavoriteState()
    const profileId = userState.user()?.profileId
    const [link, setLink] = useState<string | undefined>()
    const loggedInUser = userState.user()
    const { isMobile } = useWindowDimensions()
    const language = useLanguageState().getLanguage()

    // chime v2 hooks
    const meetingInvitation = useMeetingInvitation()
    const presenceContext = usePresenceState()
    const isLoggedInUserStaffMember =
        props.exhibitor?.allPersons.map((attendee: Person) => attendee.userId).includes(loggedInUser?.profileId || "") || false

    const getExhibitorContentManagerList = () => {
        getOrganizationContentManagerlink(profileId as string, props.organizationId as string).then((res) => {
            setLink(res?.default as string)
        })
    }
    useEffect(() => {
        getExhibitorContentManagerList()
        // eslint-disable-next-line
    }, [])

    let config = getBasisPremiumConfig(props.exhibitor?.basisPremium! as BasisPremiumType)
    const updateBookmarkedStatus = () => {
        if (props.organizationId) {
            if (loggedInUserId) {
                favorites.toggle("organization", props.organizationId)
                syncFavorites({
                    profileId: loggedInUserId,
                    body: {
                        currentTime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                        lastSyncTime: favorites.getLastSyncTime(),
                        changedFavorites: [
                            {
                                id: props.organizationId,
                                kind: "organization".toUpperCase(),
                                deleted: favorites.is("organization", props.organizationId) ? false : true,
                                lastModified: moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
                            }
                        ]
                    }
                })
                    .then((resp) => {
                        favorites.setLastSyncTime(new Date())
                        //setIsBookmarked(favorites.is("organization", props.organizationId))
                    })
                    .catch((e: { message: React.SetStateAction<string> }) => {
                        // syncFavorites failed, logged in BackendServices
                    })
            }
        }
    }

    function getVCardNote() {
        var date = moment()

        var template = branding.organizationDetailPageContent.vCardNoteTemplate
        var contentString = template.split("{$eventGuideName}").join(branding.configuration.ogTitle)
        contentString = contentString
            .split("{$date}")
            .join(language === "en" ? date.format("MMMM DD, YYYY") : date.format("DD. MMMM YYYY"))

        return contentString
    }

    function doVCard(organization: Company) {
        saveExhibitorVCard(organization, getVCardNote())
    }

    let getMainContact = loggedInUser?.organizations?.map(
        (organization) => props.exhibitor?.id! === organization.id && (organization.isMainContact as boolean)
    )
    let getContentManager = loggedInUser?.organizations?.map(
        (orga) =>
            props.exhibitor?.id! === orga.id &&
            ((orga.personTypes.findIndex((item: any) => item === "CONTENTMANAGER") > -1) as boolean)
    )

    let isMainContact = getMainContact!.findIndex((item: any) => item === true) > -1
    let isContentManager = getContentManager!.findIndex((item: any) => item === true) > -1

    function onNoStaffMembers() {
        // alert("on no staff members detail page sections")
        showNoStaffMembersModal()
    }

    return (
        <CompanyHeaderContainer>
            <CompanyHeaderRow>
                {!branding.configuration.useConferenceRoomV2 &&
                    hasAvailableStaff(props.exhibitor) &&
                    (!isExplorationOrPostEventPhase || getIamPartOf(queryParams) === "onboarding") &&
                    config.expoShowroomEnabled &&
                    isUserEdited(userState) && (
                        <CustomOverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                                <StyledTooltip
                                    id="showroom-tooltip"
                                    style={{ pointerEvents: "none", fontFamily: branding.font1 }}
                                >
                                    {branding.organizationDetailPageContent.joinShowroom}
                                </StyledTooltip>
                            }
                        >
                            <CompanyHeaderCol
                                iconVisible={branding.organizationDetailPageContent.showJoinShowRoomIcon}
                                xs={2}
                                style={{ maxWidth: "12%" }}
                                className="mt-4"
                                onClick={() => {
                                    if (hasAccessToOrganization(userState.user()!, props.exhibitor!.id))
                                        showPrivacyPolicyModal(props.exhibitor!, () => {
                                            joinShowroom(props.exhibitor as Company, userState.user()!, chime, meeting)
                                        })
                                    else showGuestModal()
                                }}
                            >
                                <HeaderIconDiv className="mb-1 h-50">
                                    {IconCameraJoin({ fill: "#FFFFFF", width: "23px", height: "23px" })}
                                </HeaderIconDiv>
                                <HeaderItemsTitle>{branding.organizationDetailPageContent.joinShowroom}</HeaderItemsTitle>
                            </CompanyHeaderCol>
                        </CustomOverlayTrigger>
                    )}
                {/* Showfloor v2 logic */}
                {branding.configuration.useConferenceRoomV2 &&
                    (hasAvailableStaff(props.exhibitor) || isLoggedInUserStaffMember) &&
                    (!isExplorationOrPostEventPhase || getIamPartOf(queryParams) === "onboarding") &&
                    config.expoShowroomEnabled &&
                    isUserEdited(userState) && (
                        <CustomOverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                                <StyledTooltip
                                    id="showroom-tooltip"
                                    style={{ pointerEvents: "none", fontFamily: branding.font1 }}
                                >
                                    {branding.organizationDetailPageContent.joinShowroom}
                                </StyledTooltip>
                            }
                        >
                            <CompanyHeaderCol
                                iconVisible={branding.organizationDetailPageContent.showJoinShowRoomIcon}
                                xs={2}
                                style={{ maxWidth: "12%" }}
                                className="mt-4"
                                onClick={() => {
                                    if (hasAccessToOrganization(userState.user()!, props.exhibitor!.id))
                                        showPrivacyPolicyModal(props.exhibitor!, () => {
                                            joinShowRoom2(
                                                props.exhibitor as Company,
                                                userState.user()!,
                                                meetingInvitation,
                                                presenceContext,
                                                history,
                                                onNoStaffMembers
                                            )
                                        })
                                    else showGuestModal()
                                }}
                            >
                                <HeaderIconDiv className="mb-1 h-50">
                                    {IconCameraJoin({ fill: "#FFFFFF", width: "23px", height: "23px" })}
                                </HeaderIconDiv>
                                <HeaderItemsTitle>{branding.organizationDetailPageContent.joinShowroom}</HeaderItemsTitle>
                            </CompanyHeaderCol>
                        </CustomOverlayTrigger>
                    )}

                {/* {props.exhibitor?.basisPremium !== BasisPremiumType.NONE &&  */}
                {!isPostEventPhase && isUserEdited(userState) && (
                    <CustomOverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                            <StyledTooltip id="meeting-tooltip" style={{ pointerEvents: "none", fontFamily: branding.font1 }}>
                                {branding.organizationDetailPageContent.requestMeeting}
                            </StyledTooltip>
                        }
                    >
                        <CompanyHeaderCol
                            iconVisible={branding.organizationDetailPageContent.showScheduleMeetingIcon}
                            xs={2}
                            style={{ maxWidth: "12%" }}
                            className="mt-4"
                            onClick={() => {
                                if (hasAccessToOrganization(userState.user()!, props.exhibitor!.id)) {
                                    showPrivacyPolicyModal(props.exhibitor!, () => {
                                        trackVisit(loggedInUserId!, props.organizationId, "CALENDARENTRY#CLICK")
                                        showCalendarEntryModal({
                                            organizationId: props.organizationId,
                                            organizationName: props.organizationName,
                                            viewMode: CalendarEntryModalViewMode.CREATE
                                        })
                                    })
                                } else showGuestModal()
                            }}
                        >
                            <HeaderIconDiv className="mb-1 h-50">
                                {IconMeetingSchedule({ stroke: "#FFF", fill: "#FFF" })}
                            </HeaderIconDiv>
                            <HeaderItemsTitle>{branding.organizationDetailPageContent.requestMeeting}</HeaderItemsTitle>
                        </CompanyHeaderCol>
                    </CustomOverlayTrigger>
                )}

                {isUserEdited(userState) && (
                    <CustomOverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                            <StyledTooltip id="recommend-tooltip" style={{ pointerEvents: "none", fontFamily: branding.font1 }}>
                                {branding.organizationDetailPageContent.registerInterest}
                            </StyledTooltip>
                        }
                    >
                        <CompanyHeaderCol
                            iconVisible={branding.organizationDetailPageContent.showRegisterInterestIcon}
                            xs={2}
                            style={{ maxWidth: "12%" }}
                            className="mt-4"
                            onClick={() => {
                                if (hasAccessToOrganization(userState.user()!, props.exhibitor!.id)) {
                                    showPrivacyPolicyModal(props.exhibitor!, () => {
                                        trackVisit(loggedInUserId!, props.organizationId, "INTEREST#CLICK")
                                        showSayHelloModal({
                                            organizationModal: true,
                                            targetId: props.organizationId,
                                            organizationName: props.organizationName
                                        })
                                    })
                                } else showGuestModal()
                            }}
                        >
                            <HeaderIconDiv className="mb-1 h-50">
                                {IconCall({ stroke: "#FFF", fill: "#FFF", width: "17", height: "17" })}
                            </HeaderIconDiv>
                            <HeaderItemsTitle>{branding.organizationDetailPageContent.registerInterest}</HeaderItemsTitle>
                        </CompanyHeaderCol>
                    </CustomOverlayTrigger>
                )}

                <CustomOverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                        <StyledTooltip id="recommend-tooltip" style={{ pointerEvents: "none", fontFamily: branding.font1 }}>
                            {branding.organizationDetailPageContent.recommend}
                        </StyledTooltip>
                    }
                >
                    <CompanyHeaderCol
                        iconVisible={branding.organizationDetailPageContent.showRecommendIcon}
                        xs={2}
                        style={{ maxWidth: "12%" }}
                        className="mt-4"
                        onClick={() =>
                            showRecommendOrganizationModal(
                                ShareTargetType.ORGANIZATION,
                                props.organizationId,
                                buildDetailLink(props.organizationId, props.organizationName, "organization")
                            )
                        }
                    >
                        <HeaderIconDiv className="mb-1 h-50"> {IconShare({ stroke: "#fff", fill: "#fff" })}</HeaderIconDiv>
                        <HeaderItemsTitle>{branding.organizationDetailPageContent.recommend}</HeaderItemsTitle>
                    </CompanyHeaderCol>
                </CustomOverlayTrigger>

                <CustomOverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                        <StyledTooltip id="bookmark-tooltip" style={{ pointerEvents: "none", fontFamily: branding.font1 }}>
                            {branding.organizationDetailPageContent.bookmark}
                        </StyledTooltip>
                    }
                >
                    <CompanyHeaderCol
                        iconVisible={branding.organizationDetailPageContent.showBookmarkIcon}
                        xs={2}
                        style={{ maxWidth: "12%" }}
                        className="mt-4"
                        onClick={() => updateBookmarkedStatus()}
                    >
                        <HeaderIconDiv className="mb-1 h-50">
                            {favorites.is("organization", props.organizationId)
                                ? branding.defaultToggleIcon
                                    ? IconBookmarkFilled({ fill: "#fff" })
                                    : IconBookmarkToggle({ fill: "#fff", width: "22", height: "22" })
                                : IconBookmarkToggle({ fill: "#fff", width: "22", height: "22" })}
                        </HeaderIconDiv>
                        <HeaderItemsTitle>{branding.organizationDetailPageContent.bookmark}</HeaderItemsTitle>
                    </CompanyHeaderCol>
                </CustomOverlayTrigger>

                {branding.organizationDetailPageContent.vCardDownloadAvailable &&
                    hasAccessToOrganization(userState.user()!, props.organizationId) && (
                        <CustomOverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                                <StyledTooltip
                                    id="download-tooltip"
                                    style={{ pointerEvents: "none", fontFamily: branding.font1 }}
                                >
                                    {branding.contactEntry.downloadVCardTitle}
                                </StyledTooltip>
                            }
                        >
                            <CompanyHeaderCol
                                iconVisible={branding.organizationDetailPageContent.showDownloadIcon}
                                xs={2}
                                style={{ maxWidth: "12%" }}
                                className="mt-4"
                                onClick={() => doVCard(props.exhibitor!)}
                            >
                                <HeaderIconDiv className="mb-1 h-50">{IconDownloadVCard({ fill: "#fff" })}</HeaderIconDiv>
                                <HeaderItemsTitle>{branding.contactEntry.downloadVCardTitle}</HeaderItemsTitle>
                            </CompanyHeaderCol>
                        </CustomOverlayTrigger>
                    )}
                {props.isStaff && !isMobile && (
                    <CustomOverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                            <StyledTooltip id="backoffice-tooltip" style={{ pointerEvents: "none", fontFamily: branding.font1 }}>
                                {branding.organizationDetailPageContent.backoffice}
                            </StyledTooltip>
                        }
                    >
                        <CompanyHeaderCol
                            iconVisible={branding.organizationDetailPageContent.showBackofficeIcon}
                            xs={2}
                            style={{ maxWidth: "12%" }}
                            className="mt-4"
                            onClick={() => {
                                history.push(
                                    buildDetailLink(props.organizationId, props.organizationName, "organization") + "/backoffice"
                                )
                            }}
                        >
                            <HeaderIconDiv className="mb-1 h-50">
                                {IconBackoffice({ stroke: "#fff", fill: "#fff" })}
                            </HeaderIconDiv>
                            <HeaderItemsTitle>{branding.organizationDetailPageContent.backoffice}</HeaderItemsTitle>
                        </CompanyHeaderCol>
                    </CustomOverlayTrigger>
                )}
                {props.isStaff &&
                    !isMobile &&
                    branding.communicationArea.editCompanyProfileLinkOption &&
                    (isMainContact || isContentManager) && (
                        <CustomOverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                                <StyledTooltip
                                    id="editCompanyProfile-tooltip"
                                    style={{ pointerEvents: "none", fontFamily: branding.font1 }}
                                >
                                    {branding.organizationDetailPageContent.editCompanyProfile}
                                </StyledTooltip>
                            }
                        >
                            <CompanyHeaderCol
                                iconVisible={branding.organizationDetailPageContent.showEditCompanyProfileIcon}
                                xs={2}
                                style={{ maxWidth: "12%" }}
                                className="mt-4"
                            >
                                <CompanyHeaderLink href={link} target="_blank" rel="noopener noreferrer">
                                    <HeaderIconDiv className="mb-1 h-50">
                                        {IconEditCompanyProfile({ fill: "#fff" })}
                                    </HeaderIconDiv>
                                    <HeaderItemsTitle>
                                        {branding.organizationDetailPageContent.editCompanyProfile}
                                    </HeaderItemsTitle>
                                </CompanyHeaderLink>
                            </CompanyHeaderCol>
                        </CustomOverlayTrigger>
                    )}
            </CompanyHeaderRow>
            <SayHelloModal />
            <CalendarModal />
            <OrganizationModal />
            <PrivacyModal />
            <GuestModal />
            <NoStaffMembersModal />
        </CompanyHeaderContainer>
    )
}

/* #endregion */

export async function joinShowroom(exhibitor: Company, loggedInUser: User, chime: any, meeting: any) {
    // Enter as Staff
    for (let person of exhibitor.allPersons) {
        if (person.userId === loggedInUser.profileId) {
            chime.createOrJoinMeeting(exhibitor.id, "showroom")
            return
        }
    }

    // Enter as interested party
    const resp = await listAttendeeInfos(getExternalMeetingId(exhibitor.id, "showroom"))
    if ((resp as BackendServiceError).httpStatus) {
        // Logged in Backendservices
    }
    const attendees = resp as AttendeeData[]
    let hasStaffOnline = false
    const availableStaff = []
    for (let person of exhibitor.allPersons) {
        for (let attendee of attendees) {
            // If staff is already online, all's good
            if (person.userId === attendee.id) {
                hasStaffOnline = true
                break
            }
        }
        if (hasStaffOnline) break
        if (person.showroomStandby) {
            availableStaff.push(person.userId)
        }
    }
    if (hasStaffOnline) chime.createOrJoinMeeting(exhibitor.id, "showroom")
    else if (availableStaff.length > 0)
        meeting.sendInvite(availableStaff[0], { meetingId: exhibitor.id, meetingKind: "showroom" })
}

export async function joinShowRoom2(
    exhibitor: Company,
    loggedInUser: User,
    meetingInvitation: MeetingInvitation,
    presenceContext: PresenceContext,
    history: any,
    onNoStaffMembers?: () => void
) {
    const queryParams: any = queryString.parse(window.location.search)
    trackVisit(loggedInUser.profileId, exhibitor.id, "EXPO", undefined)
    if (!isLivePhase && !(getIamPartOf(queryParams) === "onboarding")) {
        alert("Show room is only available in the LIVE phase")
        return
    }

    // get showroom attendees
    const resp = await listAttendeeInfos(generateMeetingURL("showroom", exhibitor.id))
    if ((resp as BackendServiceError).httpStatus) {
        alert("Something went wrong, please try again later")
        return
    }

    let showRoomAttendees = resp as AttendeeData[]
    let availableStaff: Person[] = []

    const isLoggedInUserStaffMember = exhibitor?.allPersons
        .map((attendee: Person) => attendee.userId)
        .includes(loggedInUser?.profileId || "")

    availableStaff = await getAvailableStaff(exhibitor, showRoomAttendees, loggedInUser, presenceContext)

    // join the show room if there are staff members
    if ((showRoomAttendees.length && !availableStaff.length) || isLoggedInUserStaffMember) {
        history.push(`/meetingV2/sr_${exhibitor.id}`)
    } else if (availableStaff.length && !showRoomAttendees.length && !isLoggedInUserStaffMember) {
        // if not call the first available staff member
        meetingInvitation.sendInvite(availableStaff[0].userId, InvitationType.AUDIO, {
            meetingKind: "showroom",
            meetingId: exhibitor.id
        })
    } else {
        // alert("No staff member available")
        onNoStaffMembers && onNoStaffMembers()
    }
}
const scrollPositionKey: string = "scrollPositionOrganizationDetail"

const OrganizationDetailPageContent: React.FunctionComponent = React.memo((props) => {
    const { organizationId }: any = useParams()
    const [isLoaded, setIsLoaded] = useState(false)
    const [error, setError] = useState("")
    const [exhibitor, setExhibitor] = useState<Company>()
    const windowSize = useWindowDimensions()
    const { isMobile } = useWindowDimensions()
    const [paddingBottom, setPaddingBottom] = useState(isMobile ? "10%" : aspectRatio)
    const [headerOpen, setHeaderOpen] = useState(true)

    //Privacy policy stuff and other modals
    const appState = useAppState()
    const { showGuestModal, GuestModal } = useGuestModal()

    const [isStaff, setIsStaff] = useState(false)

    const lang = useLanguageState().getLanguage()

    const userState = useLoggedInState()
    const loggedInUserId = userState.user()?.profileId
    const favorites = useFavoriteState()
    // Add banner state
    const { guestUserBannerRef, setGuestUserBannerRef } = GuestUserBannerSharedState()
    const { setHideOnScroll } = TopBannerSharedState()

    //Scroll persistance
    const [scrollPosition] = useState<number>(parseInt(window.sessionStorage.getItem(scrollPositionKey) ?? "0"))

    const headerRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        window.sessionStorage.removeItem(scrollPositionKey)
    }, [scrollPosition])

    useEffect(() => {
        appState.setCurrentMobileBreadcrumb(branding.organizationDetailPageContent.mobileBreadcrumbLabel)

        if (loggedInUserId) {
            // Checking if the user is staff of the organization
            getUserOrgaInfo({ id: loggedInUserId, organizationId: organizationId })
                .then((res) => {
                    res.hasOwnProperty("content") ? setIsStaff(true) : setIsStaff(false)
                })
                .catch((err) => {
                    setIsStaff(false)
                })
        }
    }, [loggedInUserId, organizationId, lang]) //eslint-disable-line

    useEffect(() => {
        setError("")
        setIsLoaded(true)
        const rootCategories = branding.organizationDetailPageContent.rootCategories
        let params: ExhibitorDetailsParams = {
            organizationid: organizationId,
            hideNewsdata: false,
            showPersonsEventDates: true,
            showCategoryHierarchy: branding.organizationDetailPageContent.showCategoryHierarchy || false
        }

        if (rootCategories.length > 0) params.rootCategories = rootCategories

        loadExhibitorData(params)
            .then((resp) => {
                //setIsBookmarked(favorites.is("organization", organizationId))
                setExhibitor(resp.content)
            })
            .catch((e: { message: React.SetStateAction<string> }) => {
                setIsLoaded(true)
                setError(e.message)
            })
        // eslint-disable-next-line
    }, [organizationId, lang])

    const onAccessDenied = () => {
        showGuestModal()
    }

    function handleScroll(e: any) {
        const shrinkOn = 30
        if (
            headerRef.current &&
            ((appState.isNetworkingOpen() && windowSize.width > 1300) ||
                (!appState.isNetworkingOpen() && windowSize.width > 1050))
        ) {
            if (e.scrollTop >= shrinkOn) {
                headerRef.current.style.paddingBottom = aspectRatioShrinked
                setPaddingBottom(aspectRatioShrinked)
                setHeaderOpen(false)
            } else {
                headerRef.current.style.paddingBottom = aspectRatio
                setPaddingBottom(aspectRatio)
                setHeaderOpen(true)
            }
        }

        window.sessionStorage.setItem(scrollPositionKey, e.scrollTop.toString())

        if (e.contentScrollHeight > e.clientHeight) {
            setHideOnScroll(e.scrollTop > 0)
        } else {
            setHideOnScroll(true)
        }
    }

    let content: JSX.Element = <div />

    const locations = calcBreadcrumbLocations()
    const breadcrumb = [
        { to: showfloorPageRoute, name: branding.sideIconBar.showfloorMenuText },
        { to: exhibitorsPageRoute, name: branding.navigationArea.companiesItemTitle }
    ]
    if (exhibitor) {
        breadcrumb.push({ to: locations[1], name: exhibitor.name })
    }

    if (error !== "") {
        content = (
            <div style={{ marginTop: "25%" }}>
                <BackendError />
            </div>
        )
    } else if (exhibitor) {
        if (!isLoaded) {
            content = <CenteredLoader />
        }

        const headerImageUrl = (exhibitor: Company): string => {
            if (
                branding.organizationDetailPageContent.defaultHeaderBasisPremiumTypes.includes(exhibitor.basisPremium) ||
                !exhibitor.backgroundImageURL
            )
                return "/branding/default_brand_card.jpg"
            else return exhibitor.backgroundImageURL
        }
        content = (
            <ScrollContainerRoot>
                {/* Sticky header */}
                <DesktopVersionContainer>
                    <HeaderSticky ref={headerRef} paddingBottom={paddingBottom}>
                        {exhibitor.headerVideoUrl && (
                            <VideoModal
                                width={"20%"}
                                rootCss={{ right: "20px" }}
                                videoUrl={exhibitor.headerVideoUrl}
                                videoImageUrl={exhibitor.headerVideoImageUrl}
                                headerOpen={headerOpen}
                            />
                        )}
                        <HeaderRoot>
                            <img src={headerImageUrl(exhibitor)} alt="" />
                            <CompanyHeaderRoot>
                                <CompanyHeader
                                    exhibitor={exhibitor}
                                    isStaff={isStaff}
                                    organizationId={exhibitor.id}
                                    organizationName={exhibitor.name}
                                    isBookmarked={favorites.is("organization", exhibitor.id)}
                                />
                            </CompanyHeaderRoot>
                        </HeaderRoot>
                    </HeaderSticky>
                </DesktopVersionContainer>
                <div style={{ borderBottom: branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9" }}>
                    <Breadcrumb breadcrumb={breadcrumb} />
                </div>
                {/* Main content */}
                <DetailPageSections
                    type="organization"
                    guestUserBannerRef={guestUserBannerRef}
                    onAccessDenied={onAccessDenied}
                    entity={exhibitor}
                    adjustHeightWith={335}
                    handleScroll={handleScroll}
                    initialScrollTop={scrollPosition}
                />
                <GuestModal />
            </ScrollContainerRoot>
        )
    } else {
        content = <CenteredLoader />
    }

    return (
        <>
            <GuestUserBanner setRef={setGuestUserBannerRef} />
            <TopBar />
            <DetailsRoot
                paddingBottom={paddingBottom}
                windowSize={windowSize.width}
                isNetworkingOpen={appState.isNetworkingOpen()}
            >
                {content}
            </DetailsRoot>
        </>
    )
})

export function hasAvailableStaff(exhibitor?: Company) {
    if (!exhibitor?.allPersons?.length) return false
    for (let person of exhibitor.allPersons) {
        if (person.showroomStandby) {
            return true
        }
    }
    return false
}

export async function getAvailableStaff(
    exhibitor: Company,
    showRoomAttendees: AttendeeData[],
    loggedInUser: User,
    presenceContext: PresenceContext
) {
    const showRoomAttendeesID = showRoomAttendees.map((attendee: AttendeeData) => attendee.id)
    return exhibitor.allPersons.filter(
        ({ userId, showroomStandby }) =>
            userId !== loggedInUser.profileId && // remove logged in user from the list
            showroomStandby && // keep persons with showroom standby on
            !showRoomAttendeesID.includes(userId) && // remove the staff members who are already in the showroom
            presenceContext.getPresence(userId) === PresenceType.AVAILABLE // and keep only persons who are available
    )
}

export default OrganizationDetailPageContent
