import { createState, State, useState } from "@hookstate/core"
import { MeetingStatus, useMeetingManager, useRosterState } from "amazon-chime-sdk-component-library-react"
import { DataMessage, MeetingSessionConfiguration } from "amazon-chime-sdk-js"
import { BackendServiceError } from "../../backendServices/BackendServicesUtils"
import { removeRaisedHand } from "../../backendServices/GraphQLServices"
import { ChimeMeetingData, createOrJoinMeeting, leaveRoom, MeetingKind } from "../../backendServices/MeetingServices"
import { useAppState } from "../../globalStates/AppState"
import { DataMessageType } from "../enums/DataMessageType"
import { EGMeetingStatus } from "../enums/EGMeetingStatus"
import { useMeetingInvitation } from "./MeetingInvitation"

interface StateValues {
    externalMeetingId: string | null
    externalUserId: string | null
    role: string | null
    initMeetingError: string | null
    meetingKind: MeetingKind | null
    maxAttendees: number
    maxDurationSeconds: number | null
    timeLimitChanged: boolean
    meetingTimeLeft: number | null
    egMeetingStatus: EGMeetingStatus | null
    hasMaxAttendees: boolean
    meetingChangeAccepted: boolean
    isMeetingActive: boolean
    isSwitchingRoom: boolean
}

const getStartValues = (): StateValues => {
    return {
        externalMeetingId: null,
        externalUserId: null,
        role: null,
        initMeetingError: null,
        meetingKind: null,
        maxAttendees: 5,
        maxDurationSeconds: null,
        timeLimitChanged: false,
        meetingTimeLeft: null,
        egMeetingStatus: null,
        hasMaxAttendees: false,
        meetingChangeAccepted: false,
        isMeetingActive: false,
        isSwitchingRoom: false
    }
}

export interface MeetingController {
    initMeeting: (externalMeetingId: string, externalUserId: string) => void
    startMeeting: () => void
    leaveMeeting: () => void
    getExternalMeetingId: () => string | null
    getExternalUserId: () => string | null
    getIsMod: () => boolean | null
    getInitMeetingError: () => string | null
    getEGMeetingStatus: () => EGMeetingStatus | null
    setEGMeetingStatus: (egMeetingStatus: EGMeetingStatus) => void
    getMeetingKind: () => MeetingKind | null
    getMeetingTimeLeft: () => number | null
    getTimeLimitChanged: () => boolean
    getMaxAttendees: () => number
    getHasMaxAttendees: () => boolean
    getIsMeetingChangeAccepted: () => boolean
    setIsMeetingChangeAccepted: (accepted: boolean) => void
    getIsMeetingActive: () => boolean
    getIsSwitchingRoom: () => boolean
    setIsSwitchingRoom: (value: boolean) => void
}
const state = createState<StateValues>(getStartValues())

const useStateWrapper = (state: State<StateValues>): MeetingController => {
    const meetingManager = useMeetingManager()
    const { setIsAudioVideoSettingsV2Open } = useAppState()
    const { roster } = useRosterState()
    const attendeesCount = Object.values(roster).length
    const meetingInvitation = useMeetingInvitation()

    const startMeeting = async () => {
        await meetingManager.start()

        // cleanup or set on start
        state.merge({ isMeetingActive: true, isSwitchingRoom: false })

        if (meetingManager.meetingStatus === MeetingStatus.Succeeded) {
            meetingInvitation.resetInvitationTypes()
        }
    }

    const leaveMeeting = async () => {
        if (state.value.meetingKind === "call" && attendeesCount <= 2) {
            meetingManager.audioVideo?.realtimeSendDataMessage(state.value.externalMeetingId?.slice(0, 10) || "", {
                type: DataMessageType.CALL_ENDED_FOR_ALL
            })
        }
        meetingManager.audioVideo?.stop()

        await Promise.all([
            await leaveRoom(state.value.externalMeetingId || "", state.value.externalUserId || ""),
            await meetingManager.leave()
        ])

        // cleanup on leave
        state.merge({
            role: null,
            externalMeetingId: null,
            externalUserId: null,
            meetingKind: null,
            maxAttendees: 5,
            maxDurationSeconds: null,
            timeLimitChanged: false,
            meetingTimeLeft: null,
            isMeetingActive: false,
            egMeetingStatus: null
        })
    }

    return {
        initMeeting: async (externalMeetingId: string, externalUserId: string) => {
            if (meetingManager.meetingStatus === MeetingStatus.Succeeded) {
                leaveMeeting()
            }

            try {
                let backendResponse = (await createOrJoinMeeting(externalMeetingId, externalUserId)) as ChimeMeetingData

                // if (!backendResponse.attendee || !backendResponse.chime || !backendResponse.meeting) {
                //     throw new Error("error initializng meeting on messe backend")
                // }

                if ((backendResponse as unknown as BackendServiceError).httpStatus) {
                    throw backendResponse
                }

                if (backendResponse.chime && backendResponse.attendee && backendResponse.meeting) {
                    const meetingSessionConfiguration = new MeetingSessionConfiguration(
                        backendResponse.chime.Meeting,
                        backendResponse.chime.Attendee
                    )

                    state.merge({
                        role: backendResponse.attendee.role,
                        externalMeetingId,
                        externalUserId,
                        meetingKind: backendResponse.meeting.meetingKind,
                        maxAttendees: backendResponse.meeting.maxAttendees,
                        maxDurationSeconds: backendResponse.meeting.maxDurationSeconds,
                        timeLimitChanged: backendResponse.meeting.timeLimitChanged,
                        meetingTimeLeft: backendResponse.meeting.remainingDurationMillis,
                        egMeetingStatus: null
                    })

                    try {
                        await removeRaisedHand(externalUserId)
                        await meetingManager.join(meetingSessionConfiguration)

                        if (state.value.meetingKind === "call") {
                            setIsAudioVideoSettingsV2Open(false)
                            startMeeting()
                        } else {
                            setIsAudioVideoSettingsV2Open(true)
                        }
                    } catch (error: any) {}

                    // TODO: Here should all subscriptions for data messages occur

                    // 1. Subscriptions based on user id
                    // 2. Subscriptions based on external meeting id

                    meetingManager.audioVideo?.realtimeSubscribeToReceiveDataMessage(
                        state.value.externalMeetingId?.slice(0, 10) || "", // slicing the string because of data messages limitations https://github.com/aws/amazon-chime-sdk-js/issues/1955
                        (dataMessage: DataMessage) => {
                            const messageData = dataMessage.json()
                            const messageDataType: DataMessageType = messageData.type
                            if (!messageDataType) return
                            switch (messageDataType) {
                                case DataMessageType.CALL_ENDED_FOR_ALL:
                                    leaveMeeting()
                                    break
                            }
                        }
                    )

                    if (state.value.timeLimitChanged) {
                        const delay = 5000
                        setTimeout(() => {
                            meetingManager.audioVideo?.realtimeSendDataMessage(
                                state.value.externalMeetingId?.slice(0, 10) || "",
                                {
                                    type: DataMessageType.TIMELIMITCHANGED,
                                    meetingTimeLeft: state.value.meetingTimeLeft! - delay,
                                    maxDurationSeconds: state.value.maxDurationSeconds
                                }
                            )
                        }, delay)
                    }
                }
            } catch (error: any) {
                if ((error as BackendServiceError).httpStatus) {
                    // Custom error code from the backend.
                    if (error.httpStatus === 444) {
                        if (error.responseJson?.errorCode === "meetingFull") {
                            state.merge({ egMeetingStatus: EGMeetingStatus.Full })
                            return
                        } else if (error.responseJson?.errorCode === "meetingTimeUp") {
                            state.merge({ egMeetingStatus: EGMeetingStatus.TimeUp })
                            return
                        }
                    } else if (error.httpStatus === 401) {
                        if (error.responseJson?.errorCode === "banned") {
                            state.merge({
                                egMeetingStatus: EGMeetingStatus.Banned
                            })
                            return
                        } else if (error.responseJson?.errorCode === "channelIsLive") {
                            state.merge({
                                egMeetingStatus: EGMeetingStatus.GreenRoomLive
                            })
                            return
                        } else if (error.responseJson?.errorCode === "noAuth") {
                            state.merge({
                                egMeetingStatus: EGMeetingStatus.NoAuth
                            })
                            return
                        }
                    } else if (error.httpStatus === 400) {
                        if (error.responseJson?.errorCode === "BREAKOUTaccessDenied") {
                            state.merge({
                                egMeetingStatus: EGMeetingStatus.NoModeratorInRoom
                            })
                            return
                        }
                        if (error.responseJson?.errorCode === "noConferenceRoomAccess") {
                            state.merge({
                                egMeetingStatus: EGMeetingStatus.NoConferenceRoomAccess
                            })
                            return
                        }
                        if (error.responseJson?.errorCode === "CONFERENCE_ROOMaccessDenied") {
                            state.merge({
                                egMeetingStatus: EGMeetingStatus.NoModeratorInRoom
                            })
                            return
                        }
                        if (error.responseJson?.errorCode === "noUserConferenceRoomAccesGranted") {
                            state.merge({
                                egMeetingStatus: EGMeetingStatus.NoConferenceRoomAccessGranted
                            })
                            return
                        }
                        if (error.responseJson?.errorCode === "noConferenceRoom") {
                            state.merge({
                                egMeetingStatus: EGMeetingStatus.NoConferenceRoom
                            })
                            return
                        }
                        if (error.responseJson?.errorCode === "noSuchEventDate") {
                            state.merge({
                                egMeetingStatus: EGMeetingStatus.NoEventdate
                            })
                            return
                        }
                    }
                }
                // state.merge({ initMeetingError: error.message })
            }
        },
        startMeeting: startMeeting,
        leaveMeeting: leaveMeeting,
        getExternalMeetingId: () => {
            return state.value.externalMeetingId
        },
        getExternalUserId: () => {
            return state.value.externalUserId
        },
        getIsMod: () => {
            return state.value.role === "moderator"
        },
        getInitMeetingError: () => {
            return state.value.initMeetingError
        },
        getEGMeetingStatus: () => {
            return state.value.egMeetingStatus
        },
        setEGMeetingStatus: (egMeetingStatus: EGMeetingStatus) => {
            state.merge({ egMeetingStatus })
        },
        getMeetingKind: () => {
            return state.value.meetingKind
        },
        getMeetingTimeLeft: () => {
            return state.value.meetingTimeLeft
        },
        getTimeLimitChanged: () => {
            return state.value.timeLimitChanged
        },
        getMaxAttendees: () => {
            return state.value.maxAttendees
        },
        getHasMaxAttendees: () => {
            return Object.values(roster).length >= state.value.maxAttendees
        },
        getIsMeetingChangeAccepted: () => {
            return state.value.meetingChangeAccepted
        },
        setIsMeetingChangeAccepted: (accepted: boolean) => {
            state.meetingChangeAccepted.merge(accepted)
        },
        getIsMeetingActive: () => {
            return state.value.isMeetingActive
        },
        getIsSwitchingRoom: () => {
            return state.value.isSwitchingRoom
        },
        setIsSwitchingRoom: (value: boolean) => {
            state.isSwitchingRoom.merge(value)
        }
    }
}
/**
 * This controller is used for gluing the sdk and our backend together. We initialize here the meeting
 * via the meetingManager and our backend service in the same time. Same goes for leaving the meeting.
 * I recomend to do other stuff directly via the meetingManager, but starting and leaving the
 * meeting with this controller.
 */
export const useMeetingController = (): MeetingController => useStateWrapper(useState(state))
